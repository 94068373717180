import React from "react";

import Header from "../pages/header/Header";
import Footer from "../pages/footer/Footer";

export default function Layout({ children }) {
  return (
    <div className="app-main-content-wrapper">
      <Header />
      <div className="app-page-wrapper">{children}</div>
      <Footer />
    </div>
  );
}
