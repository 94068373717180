import React from "react";
import { Helmet } from "react-helmet";
import header from "../../../images/casestudy/casehead.jpg";
import case3 from "../../../images/casestudy/cs-automibile.png";
import case4 from "../../../images/casestudy/cs-bookrelease.png";
import case1 from "../../../images/casestudy/cs-fmcg.png";
import case5 from "../../../images/casestudy/cs-healthsupplement.png";
import case6 from "../../../images/casestudy/cs-hospitality.png";
import case2 from "../../../images/casestudy/cs-insurance.png";
import case7 from "../../../images/casestudy/cs-jewellery.png";
import case8 from "../../../images/casestudy/cs-lifestylepersonalcare.png";
import case9 from "../../../images/casestudy/cs-medicalfacility.png";
import case10 from "../../../images/casestudy/cs-realestate.png";
import case11 from "../../../images/casestudy/cs-retailecommerce.png";
import case12 from "../../../images/casestudy/cs-textile.png";
import Layout from "../../core/Layout";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";
import CaseStudyPopBox from "./CaseStudyPopBox";

export default function Casestudy() {
  window.scrollTo(0, 0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const openCaseStudyPopup = () => {
    setOpenPopup(!openPopup);
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>Case Studies | Digital Marketing | Services | DigiPrism</title>
        <meta name="description" content="36 Case Studies | 30 Lakhs+ Revenue Generated | 12 Business Domains | 25 Services | Up to 3400% Increase in Revenue | Explore Your Requirement" />
      </Helmet>
      <div className="app-blog-page app-content-merk">
        <div className="app-pos-bg">
          <div className="app-container">
            <div className="app-blog-header">
              <img src={header} alt="Case Study" />
              <div className="app-wrap-cover-page">
                <h2>Case Studies</h2>
                <p>Been There; Done That.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-blogs">
        <div className="app-container">
          <div className="heading-blog-head">
            <h3>Different and Simple</h3>
          </div>
          <div className="app-casestudy-list">
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case1} alt="Case Study" /></span>
              <h4>Products &amp; FMCG</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case2} alt="Case Study" /></span>
              <h4>Insurance</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case3} alt="Case Study" /></span>
              <h4>Automobile</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case4} alt="Case Study" /></span>
              <h4>Book Release</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case5} alt="Case Study" /></span>
              <h4>Health Supplement</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case6} alt="Case Study" /></span>
              <h4>Hospitality</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case7} alt="Case Study" /></span>
              <h4>Precious Jewelry</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case8} alt="Case Study" /></span>
              <h4>Lifestyle</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case9} alt="Case Study" /></span>
              <h4>Medical Facility</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case10} alt="Case Study" /></span>
              <h4>Real Estate</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case11} alt="Case Study" /></span>
              <h4>Retail &amp; Ecommerce</h4>
            </div>
            <div className="app-case-item-list" onClick={() => openCaseStudyPopup()}>
              {/* <b onClick={() => openCaseStudyPopup()}>Download</b> */}
              <span><img src={case12} alt="Case Study" /></span>
              <h4>Textile &amp; Garments</h4>
            </div>
          </div>
          {/* <div className="app-blogs-list">
            <div className="app-blog-item">
              <span><img src={case1} alt="Case Study" /></span>
              <div className="app-blog-desc">
                <div className="head-blg">How modern real estate agencies are shaping the industry</div>
                <div className="case-desc-blg"><span onClick={() => openCaseStudyPopup()}>→ &nbsp;Download</span></div>
              </div>
            </div>
            <div className="app-blog-item">
              <span><img src={case2} alt="Case Study" /></span>
              <div className="app-blog-desc">
                <div className="head-blg">Role of AI-powered chatbots for customer service post Covid-19</div>
                <div className="case-desc-blg"><span onClick={() => openCaseStudyPopup()}>→ &nbsp;Download</span></div>
              </div>
            </div>
            <div className="app-blog-item">
              <span><img src={case3} alt="Case Study" /></span>
              <div className="app-blog-desc">
                <div className="head-blg">Everything you need to know about surrogate advertising</div>
                <div className="case-desc-blg"><span onClick={() => openCaseStudyPopup()}>→ &nbsp;Download</span></div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {openPopup && <CaseStudyPopBox openCaseStudyPopup={openCaseStudyPopup} />}
    </Layout>
  );
}
