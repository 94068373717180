import React from "react";
import AnimatedCursor from "react-animated-cursor";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import mail from "../images/mail.svg";
import Login from "./admin/auth/Login";
import Register from "./admin/auth/Register";
import ProtectedRoute from "./admin/core/ProtectedRoute";
import BasicInfo from "./admin/dashboard/BasicInfo";
import Contact from "./admin/dashboard/Contact";
import Dashboard from "./admin/dashboard/Dashboard";
import Quote from "./admin/dashboard/Quote";
import UserList from "./admin/dashboard/UserList";
import About from "./pages/about/About";
import Blog1 from "./pages/blogs/Blog1";
import Blog2 from "./pages/blogs/Blog2";
import Blog3 from "./pages/blogs/Blog3";
import Blog4 from "./pages/blogs/Blog4";
import Blogs from "./pages/blogs/Blogs";
import Career from "./pages/career/Career";
import Casestudy from "./pages/casestudy/Casestudy";
import Contactus from "./pages/contactus/Contactus";
import Home from "./pages/home/Home";
import Automobile from "./pages/industry/Automobile";
import ConsumerProducts from "./pages/industry/ConsumerProducts";
import FinancialServices from "./pages/industry/FinancialServices";
import HealthSupplement from "./pages/industry/HealthSupplement";
import Hospitality from "./pages/industry/Hospitality";
import Insurance from "./pages/industry/Insurance";
import Jewelry from "./pages/industry/Jewelry";
import LifestylePersonal from "./pages/industry/LifestylePersonal";
import MedicalFacility from "./pages/industry/MedicalFacility";
import RealEstate from "./pages/industry/RealEstate";
import RetailEcommerce from "./pages/industry/RetailEcommerce";
import TextileGarments from "./pages/industry/TextileGarments";
import Leadership from "./pages/leadership/Leadership";
import Disclaimer from "./pages/policies/Disclaimer";
import Legalnotice from "./pages/policies/Legalnotice";
import TermsCondtions from "./pages/policies/TermsCondtions";
import Services from './pages/services/Services';
import ServicesContentMarketing from "./pages/services/ServicesContentMarketing";
import ServicesDesigningServices from "./pages/services/ServicesDesigningServices";
import ServicesGrowthHacking from "./pages/services/ServicesGrowthHacking";
import ServicesInfluencerAffiliateMarketing from "./pages/services/ServicesInfluencerAffiliateMarketing";
import ServicesOnlineReputationManagement from "./pages/services/ServicesOnlineReputationManagement";
import ServicesSearchEngine from "./pages/services/ServicesSearchEngine";
import ServicesSocialMedia from "./pages/services/ServicesSocialMedia";
import ServicesWebAppDevelopment from "./pages/services/ServicesWebAppDevelopment";
import Testimonials from "./pages/testimonials/Testimonials";
import Work from "./pages/work/Work";

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/work" component={Work} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/our-team" component={Leadership} />
        <Route exact path="/testimonials" component={Testimonials} />
        <Route exact path="/contact-us" component={Contactus} />
        <Route exact path="/join-us" component={Career} />
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/case-study" component={Casestudy} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/search-engine-ranking" component={ServicesSearchEngine} />
        <Route exact path="/social-media-marketing" component={ServicesSocialMedia} />
        <Route exact path="/designing-services" component={ServicesDesigningServices} />
        <Route exact path="/content-marketing" component={ServicesContentMarketing} />
        <Route exact path="/influencer-and-affiliate-marketing" component={ServicesInfluencerAffiliateMarketing} />
        <Route exact path="/online-reputation-management" component={ServicesOnlineReputationManagement} />
        <Route exact path="/web-app-developments" component={ServicesWebAppDevelopment} />
        <Route exact path="/growth-hacking" component={ServicesGrowthHacking} />
        <Route exact path="/automobile" component={Automobile} />
        <Route exact path="/insurance" component={Insurance} />
        <Route exact path="/hospitality" component={Hospitality} />
        <Route exact path="/real-estate" component={RealEstate} />
        <Route exact path="/medical-facility" component={MedicalFacility} />
        <Route exact path="/retail-and-ecommerce" component={RetailEcommerce} />
        <Route exact path="/health-supplement" component={HealthSupplement} />
        <Route exact path="/book-release" component={FinancialServices} />
        <Route exact path="/textile-and-garments" component={TextileGarments} />
        <Route exact path="/lifestyle-and-personal-care" component={LifestylePersonal} />
        <Route exact path="/precious-and-semi-precious-jewelry" component={Jewelry} />
        <Route exact path="/consumer-products-and-fmcg" component={ConsumerProducts} />
        <Route exact path="/privacy-policy" component={Legalnotice} />
        <Route exact path="/terms-and-condtions" component={TermsCondtions} />
        <Route exact path="/cookies-policy" component={Disclaimer} />
        <Route exact path="/how-modern-real-estate-agencies-are-shaping-the-industry" component={Blog1} />
        <Route exact path="/role-of-ai-powered-chatbots-for-customer-service-post-covid-19" component={Blog2} />
        <Route exact path="/everything-you-need-to-know-about-surrogate-advertising" component={Blog3} />
        <Route exact path="/upskilling-talents-trend-going-forward-for-organizations" component={Blog4} />

        <Route exact path="/admin/newadminfolk" component={Register} />
        <Route exact path="/admin/authlogin" component={Login} />
        <ProtectedRoute exact path="/admin/dashboard" component={Dashboard} />
        <ProtectedRoute exact path="/admin/contact" component={Contact} />
        <ProtectedRoute exact path="/admin/basic-info" component={BasicInfo} />
        <ProtectedRoute exact path="/admin/quote" component={Quote} />
        <ProtectedRoute exact path="/admin/listUser" component={UserList} />
      </Switch>
      <AnimatedCursor />
      <Link to="/contact-us" className="fixed-contect">
        <img src={mail} alt="Contact us" />
      </Link>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="I Understand"
        declineButtonText="Decline"
        cookieName="CookieConsent"
        expires={365}
        // onAccept={() => alert("Accept was triggered by clicking the Accept button")}
        // onDecline={() => alert("nay!")}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </BrowserRouter>
  );
}
