import React, { useEffect, useRef } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

SwiperCore.use([Navigation, Pagination]);
export default function HomeTestimonials() {
  const pathRef = useRef();

  useEffect(() => {
    let svgPathElement = pathRef.current;
    let length = svgPathElement.getTotalLength();
    svgPathElement.style.strokeDasharray = length;
    svgPathElement.style.strokeDashoffset = length;
    const lineAnimation = () => {
      let docElement = document.documentElement;
      let scrollpercent =
        (document.body.scrollTop + docElement.scrollTop + 460) /
        (docElement.scrollHeight - docElement.clientHeight);
      let draw = length * scrollpercent;
      svgPathElement.style.strokeDashoffset = length - draw * 6.2;
    };
    window.addEventListener("scroll", lineAnimation);
  }, []);

  return (
    <div className="app-home-testimonials">
      <div className="app-container">
        <div className="app-inside-testimonials">
          <div className="app-testimonials-left">
            <Swiper
              navigation
              spaceBetween={20}
              pagination={{ clickable: true }}
              loop={true}
              autoplay={{ delay: 2500 }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 1,
                }
              }}
            >
              <SwiperSlide>
                <div className="app-testimonials-list">
                  <h5>Subham Mohanty</h5>
                  <b>CEO &amp; Founder, My Nirmaan</b>
                  <p>
                    We are very impressed with the work. You have delivered what
                    we were looking for..
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="app-testimonials-list">
                  <h5>Richard Croft</h5>
                  <b>Pearl Studio</b>
                  <p>
                    Services taken: ASP.NET website, complete SEO, blog, email
                    service. Very much satisfied. Will refer.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="app-testimonials-list">
                  <h5>S. S. Mittal</h5>
                  <b>Co-Owner, Droob Global Homes</b>
                  <p>
                    Rajib and Monalisa introduced my brand to 17 influencer. 6
                    of them still work for my brand. Their negotiations are
                    magic.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="app-testimonials-list">
                  <h5>Amisha Kaushal</h5>
                  <b>Organic Chemical Researcher &amp; Motivator</b>
                  <p>
                    My skincare brand acquired more than 10,000 followers thanks
                    to DigiPrism’s graphics and illustrations.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="app-testimonials-list">
                  <h5>Soutam Banerjee</h5>
                  <b>The Blue French Horn</b>
                  <p>
                    The Blue French Horn” received 700+ likes from paid Facebook
                    marketing. Very good job done with audience targeting.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="app-testimonials-list">
                  <h5>Akash Agarwal</h5>
                  <b>CFO, D Mart</b>
                  <p>
                    When I started my business in August, I had no idea on Paid
                    Ads. They not only worked on it but also showed us the
                    process on video call. Innovative.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="app-testimonials-right">
            <h4>
              <span>Customers</span> speak
            </h4>
          </div>
          <div className="app-anim-last">
            <svg width="339" height="308" viewBox="0 0 339 308" fill="none">
              <path
                ref={pathRef}
                stroke="white"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M22 2C-43 201 70.7588 185.768 130 159C265 98 367 196 327.55 306"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
