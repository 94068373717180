import React from "react";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/admin/authlogin";
  };
  return (
    <div className="app-dashboard-sidebar">
      <div className="app-sideBars">
        <div className="app-dash-logo">
          <span>DigiPrism</span>
        </div>
        <NavLink activeClassName="isActiveCls" to="/admin/dashboard">Dashboard</NavLink>
        <NavLink activeClassName="isActiveCls" to="/admin/contact">Contact</NavLink>
        <NavLink activeClassName="isActiveCls" to="/admin/basic-info">Main Info</NavLink>
        <NavLink activeClassName="isActiveCls" to="/admin/quote">Get a Quote</NavLink>
        <NavLink activeClassName="isActiveCls" to="/admin/listUser">User List</NavLink>
        <span onClick={() => handleLogout()}>Logout</span>
      </div>
    </div>
  );
}
