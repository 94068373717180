import React from "react";
import { Helmet } from "react-helmet";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from 'react-router-dom';
import caseImg from "../../../images/casestudy/cs-jewellery.png";
import img1 from "../../../images/industry-banners/top/11-1.jpg";
import img2 from "../../../images/industry-banners/top/11-2.jpg";
import img3 from "../../../images/industry-banners/top/11-3.jpg";
import Layout from "../../core/Layout";
import CaseStudyPopup from "../services/CaseStudyPopup";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function Jewelry() {
  window.scrollTo(0, 0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const openCaseStudyPopup = () => {
    setOpenPopup(!openPopup);
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>DigiPrism | Best Digital Marketing For Jewelry Brands</title>
        <meta name="description" content="Ever-increasing gold price stopping customers from new purchases? Showcase Your Designs with Display Ads, Content Marketing &amp; Social Media. Shall We Start?" />
      </Helmet>
      <div className="app-banner-indus jewelry">
        <div className="app-container">
          <div className="app-inside-wrapper">
            <h2>
              DigiPrism{" "}
              <span>
                Precious &amp; <br />
                Semi-Precious Jewelry
              </span>
            </h2>
            <p>
              Getting harder to explain to customers the difference between <br/>
              stone quality? Ever-increasing gold price stopping customers <br/>from new purchases?
            </p>
            <p>Relax and leave it to Us!</p>
            <Link to="/contact-us" className="app-tell-us">Get A Free Consultation</Link>
          </div>
        </div>
      </div>
      <div className="app-indust-head-all">
        <h3>Your Problems... Sorted!</h3>
      </div>
      <div className="app-left-industry-area jewelry">
        <div className="app-container">
          <div className="app-flx-wrapp">
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>1.</span>
                Lack of social reach
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Low engagement rate</li>
                    <li>No social shares</li>
                    <li>High average response time</li>
                    <li>Lack of social identity </li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Scheduled social media posting</li>
                    <li>Social media API integrations</li>
                    <li>Social media chatbots</li>
                    <li>Corporate logo redesigning</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>2.</span>
                Lack of brand awareness
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Low SERP ranking</li>
                    <li>Low rate of ad impressions</li>
                    <li>Bigger competitors</li>
                    <li>Lack of local customers</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>SEO-friendly webpages</li>
                    <li>Facebook/Instagram advertising</li>
                    <li>Marketing with storytelling</li>
                    <li>Nano influencer marketing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>3.</span>Low customers’ interests
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Low post views</li>
                    <li>Low website crawlability</li>
                    <li>Customers not informed</li>
                    <li>Customers not responding</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Infographics and visual content posting</li>
                    <li>Long-form content posting</li>
                    <li>e-book posting and marketing</li>
                    <li>Public relationship building</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>4.</span>Lead generation problems
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Bad sales leads</li>
                    <li>Vague website</li>
                    <li>Lack of communication</li>
                    <li>No brand loyalty</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Lead magnet marketing</li>
                    <li>Landing page redesigning</li>
                    <li>Follow-up calls and emails</li>
                    <li>Forum for User-Generated Content</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-three-channel">
        <div className="app-container">
          <div className="app-top-heading-cov jewelry">
            <h3>
              Top 3 Marketing Channels for <br />
              <span>Precious &amp; Semi-Precious Jewelry Industry</span>
            </h3>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Social Media Optimization</h4>
                <p>
                  SMO is the act of publishing social media content to engage
                  the target audience. Better brand identity, an increased
                  business reach, and higher brand awareness are the results of
                  SMO campaigns. SMO helps with web traffic lead generation.
                  Sharable social media content also improves the search engines
                  ranking.
                </p>
              </div>
              <span className="app-topright"><img src={img1} alt="Social Media Optimization" /></span>
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M5 1C73 9 141 78.5 130 183M130 183L141 170.5M130 183L119 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="-0.00012207"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-rgt">
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <span className="app-topleft"><img src={img2} alt="Visual Search" /></span>
              <div className="app-topright">
                <h4>Visual Image Search</h4>
                <p>
                  Visual search trends have transformed the way of searching
                  products online. Images and graphics have a considerable
                  increase in the number of guests visiting the site. The visual
                  search technology enables users to search through images to
                  find the desired product.
                </p>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>User-Generated Content</h4>
                <p>
                  User-generated content is a social proof for credibility
                  development. It highlights customers’ feelings about your
                  brand. UGC is considered as the most trustworthy, authentic,
                  and reliable content. Wider reach, diverse, creative content,
                  and brand advocacy development are values from UGC.
                </p>
              </div>
              <span className="app-topright"><img src={img3} alt="User-Generated Content" /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="app-scroll-items-cover wrapp-tree-two">
        <div className="app-container">
          <div className="app-scrl-wrapper">
            <div className="app-spl-latest-left">
              <span>
                <img src={caseImg} alt="case study" />
                <div className="download-btn" onClick={() => openCaseStudyPopup()}>Download</div>
              </span>
              <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                <g filter="url(#filter0_d)">
                  <path
                    d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="6 7.9"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="-9.15527e-05"
                    width="146"
                    height="192"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <p><Link to="/case-study">More Case Study</Link></p>
            </div>
            <div className="app-spl-latest-right">
              <span>
                <svg viewBox="0 0 351.128 351.128">
                  <path d="M72.326,147.33c4.284-26.928,37.944-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.224-0.612,2.448-1.224,3.06-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.976,11.628C38.054,85.518,1.946,136.313,3.782,184.662c-6.12,32.437-4.896,67.32,4.284,96.084c6.12,18.36,23.868,27.54,42.228,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.524-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C115.778,165.078,66.818,181.602,72.326,147.33z" />
                  <path d="M274.286,147.33c4.284-26.928,37.943-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.225-0.612,2.448-1.224,3.061-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.977,11.628c-35.496,29.376-71.604,80.172-69.768,128.52c-6.12,32.437-4.896,67.32,4.283,96.084c6.12,18.36,23.868,27.54,42.229,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.523-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C317.738,165.078,268.166,181.602,274.286,147.33z" />
                </svg>
              </span>
              <h6>
                Services taken: ASP.NET website, complete SEO, blog, email
                service. Very much satisfied. Will refer.
              </h6>
              <b>Richard Croft</b>
              <p>Pearl Studio</p>
              <div className="app-bg-dot">
                <svg viewBox="0 0 266 246" fill="none">
                  <circle cx="3" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="63" r="3" fill="#F7F7F7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-faq-cover">
        <div className="app-container">
          <div className="app-faq-wrap">
            <div className="app-top-heading-cov jewelry">
              <h3>
                Frequently Asked <span>Questions</span>
              </h3>
            </div>
            <div className="app-faq-wrapper jewelry">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have recently established my jewelry business in my
                      locality. How will I gain the trust of my potential
                      customers?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      To help you in gaining credibility, first start a robust
                      social media marketing campaign for your jewelry business
                      specifically catering to your local and relevant audience.
                    </p>
                    <p>
                      With blogs, visual content, and regular interactions, let
                      us educate your target audience. Jewelry graphics
                      submission on Instagram and other social platforms, GMB
                      Optimization, actionable Call-to-Action buttons,
                      Custom-build landing pages. customer referrals, online
                      synergistic business collaboration are a few of the many
                      things we can do to make your local business boom.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have a new collection of fashion jewelry. How do I
                      showcase it to several customers?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      As your customers are using multiple digital platforms,
                      you can go social to showcase them the products with
                      Google product display ads. You should be creating a
                      website with your product image gallery with a well-done
                      SEO and content optimization.
                    </p>
                    <p>
                      Also, multi-channel customer re-targeting with an email
                      drip campaign, user-friendly website with quick response
                      time, off-page and on-page search engine optimization, a
                      content hub for oblivious, afflicted and informed
                      customers, pay-per-click advertising, Mobile App, QR Code,
                      and GMB listing &amp; Optimization are few of the way we
                      can make it work just the right for you!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I want valuable traffic to my jewelry website. How can you
                      help me?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We think that the PPC campaign is the best solution to
                      this problem. You will receive valuable traffic and a
                      higher rate of conversions when paid ads are run with the
                      correct audience segment and strategy. Moreover, you need
                      to pay only for the number of clicks on the advertisement.
                      Our marketers will help you in creating the ad copy with
                      relevant keywords. Wait and see the desired results in
                      your website traffic.
                    </p>
                    <p>
                      Also, nowadays social media ads are quite targeted and
                      impactful. With the correct combination of audience,
                      shorter average response time, proper utilization of brand
                      advocate powers, user crowdsourcing, and Geo-targeting you
                      can reach people who need your products.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      My store has precious and semi-precious gemstones. How do
                      I educate my customers about those stones?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      As most of your customers use online sites make purchases,
                      you can approach the content marketing world to accomplish
                      your goal.
                    </p>
                    <p>
                      You can create an authoritative blogging and newsletter
                      section on your website. We have the best team of writers
                      for informative blogs and articles about your gemstones.
                      How-to guides and skyscraper content methodology works
                      best in these scenarios.
                    </p>
                    <p>
                      Not only the content marketing, our dedicated team for SEO
                      web content developers know which words to put exactly
                      where to make your customers convert.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have found a reduction in the number of sales of my
                      jewelry. How will I make my business profitable?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Our SEO team will check out both off-page and on-page SEO
                      activities of your site. In some cases, slow loading pages
                      and broken links can affect the bounce rate of your site.
                    </p>
                    <p>
                      Also, forum marketing, email marketing campaign, guest
                      posting, market segment analysis, video marketing, and
                      marketing automation can directly help you in boosting
                      sales.
                    </p>
                    <p>
                      We will analyze each of the factors and give you a report
                      on website performance. If necessary, we may go for paid
                      campaigns to restore your business status and increase
                      your revenues.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I want to gain local customers for my jewelry stores. Is
                      digital marketing essential for me?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, of course. Local SEO will give you the best result
                      here. By creating a proper my business page, your store
                      location with other important details will be visible to
                      your potential customers in the locality.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Show me a detailed Precious &amp; Semi-Precious Jewelry
                      success story.
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="divCls">
                      <b>About the company</b>
                      This jewelry brand started its business in India in the
                      year 2004. with a relentless passion for creating trendy
                      jewelries. The core principles of this brand are- superb
                      craftsmanship, premium quality, unquestionable service,
                      and ultimate purity. However, with eccentric creations for
                      the target customers and our digital marketing efforts,
                      this brand has gained a leading position in the jewelry
                      market. With the right initiative at the right time, the
                      company has now become successful in drawing customers to
                      its brick-and-mortar jewelry stores and online platform.
                    </p>
                    <div className="divCls">
                      <b>Condition before we started our campaign</b>
                      <ul>
                        <li>
                          Due to high competitiveness, the company found
                          difficulties in beating its competitors.
                        </li>
                        <li>
                          Although it was popular in the local market, it could
                          not increase its business reach.
                        </li>
                        <li>
                          The online platform of the jewelry brand was not much
                          optimized.{" "}
                        </li>
                        <li>
                          Due to the release of new Google algorithms and lack
                          of updates, the website became outdated.
                        </li>
                        <li>
                          Being busy with offline marketing, like newspaper ads
                          and billboards, its online presence was not strong.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Problem statement</b>
                      <ul>
                        <li>Very limited traffic flow</li>
                        <li>No noticeable lead conversion</li>
                        <li>Low rank in the organic search results</li>
                        <li>
                          No credibility developed in spite of designing quality
                          jewelries{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Our objectives/KPIs</b>
                      <ul>
                        <li>To get the website on the first page of SERP</li>
                        <li>To make it mobile-friendly</li>
                        <li>To reduce bounce rate by more than 50%</li>
                        <li>To increase social media followers</li>
                        <li>To increase the engagement rate</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>The way we have achieved our KPIs</b>
                      <p>
                        Lack of digital interactions, improper website,
                        irrelevant keywords, and slow page loading have been the
                        major barriers to the brand’s success.
                      </p>
                      <b>Search Engine Optimization</b>
                      <p>
                        The jewelry company had started its SEO about a year ago
                        before making a deal with us. However, it had not seen
                        any noticeable result. Here is what we have done in the
                        past 6 months with our continuous SEO efforts.
                      </p>
                      <ul>
                        <li>
                          First, we analyzed the content gap and website
                          architecture
                        </li>
                        <li>
                          Then, we head on removing Zombie pages, like archived
                          pages, search result pages, boilerplate content, tag
                          pages, and old press releases
                        </li>
                        <li>
                          We also identified Google indexing report and provided
                          index coverage report.
                        </li>
                        <li>
                          Our mobile site optimization techniques also showed
                          tangible result
                        </li>
                      </ul>
                      <p>Besides, we checked out your</p>
                      <ul>
                        <li>SEO backlinks</li>
                        <li>Spammy links and black hat links</li>
                        <li>Broken links</li>
                        <li>
                          404 Error Page and wrong URLs causing an effect on
                          your webpage load speed
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>On-page SEO</b>
                      <p>
                        We know that hierarchical webpage content plays a role
                        in optimizing a website. With webpage schema markup,
                        keyword research, and other on-site factors, we have
                        revamped the jewelry website to ensure better result
                        from the SEO campaign.
                      </p>
                      <ul>
                        <li>
                          Our process started with keyword repetition analysis
                          to maintain its density
                        </li>
                        <li>
                          We also added short-tail and long-tail keywords,
                          header tags and title tags.
                        </li>
                        <li>
                          We made the headlines and subheadings more effective
                        </li>
                        <li>
                          Complementary color and font styles are chosen
                          considerately
                        </li>
                        <li>
                          Fast-loading compressed image files inserted to
                          webpages
                        </li>
                        <li>
                          Lastly, we increased authenticity with outbound and
                          inbound links
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Off-page SEO</b>
                      <p>
                        We have reached our marketing activities beyond the
                        official website of the jewelry brand. Our off-page SEO
                        has enabled the site to get the link juice from quality
                        backlinks. This is how we have focused on off-page SEO
                        factors
                      </p>
                      <ul>
                        <li>Added meaningful blog comments</li>
                        <li>Developed connection with a community </li>
                        <li>Built relationship with influencer reach</li>
                        <li>Publish guest blogs in high DA sites</li>
                        <li>
                          Invested more time and resources in social bookmarking
                          and social networking
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Search Engine Marketing</b>
                      <p>
                        The jewelry company was suffering from low online
                        visibility. To bring its brand name in front of the
                        public and the target customers, we have elevated its
                        webpages. What’s more, we have made several other
                        efforts for the SEM campaign
                      </p>
                      <ul>
                        <li>
                          Strengthened the SEM campaign with Direct Response
                          Marketing{" "}
                        </li>
                        <li>
                          Identified the target audience with thorough research
                        </li>
                        <li>
                          Found highly searched terms with keyword research
                          tools
                        </li>
                        <li>
                          Optimized the web content with clear content and clear
                          codes
                        </li>
                        <li>
                          Garnered links from customers, vendors, and business
                          partners
                        </li>
                        <li>
                          The website submitted for indexing by Google, Bing,
                          and Yahoo
                        </li>
                        <li>
                          Managed the paid search advertisements (PPC and Google
                          Ads) with attractive CTA
                        </li>
                        <li>
                          Finally, we measured CPR, CPC, bounce rate, clicks,
                          impressions, and ROI.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Content optimization</b>
                      <p>
                        For 3 months, we consistently worked on the content to
                        match it with the user intent. Our goals were to make
                        the content responsive to Navigational Queries,
                        Informational Queries, and Transactional Queries.
                        However, the trust factor was of top priority to us
                        while dealing with the content. Here is how we have
                        achieved our goals.
                      </p>
                      <ul>
                        <li>
                          First, we have created marketing personas to make out
                          the taste and preferences of the target audience.
                        </li>
                        <li>
                          We strived our best to hone the content of the jewelry
                          website. We have added keywords to the body content,
                          headings, and subheadings.
                        </li>
                        <li>
                          Our team has paid attention to legible fonts, font
                          sizes, and eye-soothing font colors.
                        </li>
                        <li>
                          Furthermore, we have double-checked grammar and edited
                          the content.
                        </li>
                        <li>
                          Then, by adding multimedia, like videos, images, and
                          infographics, we have accomplished our content
                          optimization campaign.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Email marketing campaign</b>
                      <p>
                        The jewelry brand initially failed to nurture their
                        leads. Email open rates were low, spam markings were
                        high, and there was an increasing number of inactive
                        subscribers. In the following ways, we have solved these
                        problems
                      </p>
                      <ul>
                        <li>
                          During our consultation, we came to know about our
                          client’s goals- New subscribers, more engagements,
                          re-engagements, and lead nurturing.
                        </li>
                        <li>
                          By detecting user behavior, we created a targeted
                          email list.
                        </li>
                        <li>
                          Based on our email segmentation, we started sending
                          welcome messages, follow-up emails, promotional
                          emails, and informative newsletters.
                        </li>
                      </ul>
                      <p>Besides, we focused on</p>
                      <ul>
                        <li>High-converting email subject lines</li>
                        <li>Engaging copy</li>
                        <li>Compelling CTA</li>
                        <li>The jewelry brand personality</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Influencer marketing</b>
                      <p>
                        The audience reach of the jewelry brand was very
                        limited. We made the best efforts to increase the
                        engagement level, number of followers, and post views.
                        Our networking with social media influencers for jewelry
                        business helped us a lot.{" "}
                      </p>
                      <ul>
                        <li>
                          Our approach started with a search for the best
                          influencers in the jewelry niche.
                        </li>
                        <li>
                          We identified the influencing capability of those
                          influencers.
                        </li>
                        <li>
                          By analyzing the purchase intent of potential buyers,
                          we reviewed and optimized the influencer content.
                        </li>
                        <li>
                          We paid special attention to influencer marketing ads
                          to make them relevant to jewelry brand.
                        </li>
                      </ul>
                      <p>
                        While checking our KPIs, we found these achievements
                      </p>
                      <ul>
                        <li>40% more traffic</li>
                        <li>35% increase in revenues</li>
                        <li>45 increase in engagement rates</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Social Media Marketing</b>
                      <p>
                        We increased the brand visibility in social platforms,
                        including Facebook, Twitter, Pinterest, and Instagram.
                        Presently, the brand has more than a million followers
                        in social media. Here is how we have achieved it.
                      </p>
                      <ul>
                        <li>
                          With social media analytic tools, we derived
                          demographic data.
                        </li>
                        <li>
                          Using hashtags and regular posts, we increased the
                          post reach.
                        </li>
                        <li>
                          Our visual content was highly effective in gathering
                          the attention of the audience{" "}
                        </li>
                      </ul>
                      <p>
                        Our SMM campaigns caused improvements to these metrics
                      </p>
                      <ul>
                        <li>Social Share of Voice</li>
                        <li>Brand awareness</li>
                        <li>Audience growth rate</li>
                        <li>Click-through-rate</li>
                        <li>Social media conversion rate</li>
                        <li>Customer Satisfaction score</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Outcome</b>
                      <p>
                        We have gone beyond the anticipation of our client. The
                        jewelry company has seen noticeable result in their
                        online presence. We have summarized our success briefly.
                      </p>
                      <ul>
                        <li>More than 80% increase in ROI</li>
                        <li>70% decrease in the bounce rate.</li>
                        <li>Over 50% increase in conversion</li>
                        <li>A significant push to the SERP rank</li>
                        <li>A high engagement rate in the social platforms</li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {openPopup && <CaseStudyPopup openCaseStudyPopup={openCaseStudyPopup} />}
    </Layout>
  );
}
