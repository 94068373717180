import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import blog3 from "../../../images/blog/advert.jpg";
import blog2 from "../../../images/blog/ai.jpg";
import header from "../../../images/blog/blog-head.png";
import blog1 from "../../../images/blog/realestate.jpg";
import blog4 from "../../../images/blog/skill.jpg";
import Layout from "../../core/Layout";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function Blogs() {
  window.scrollTo(0, 0);
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>Blogs | Digital Marketing | Technology | DigiPrism</title>
        <meta name="description" content="Regular Digital Marketing Updates | Software Development &amp; Application Development Trends | Artificial Intelligence | Business Consultation News" />
      </Helmet>
      <div className="app-blog-page app-content-merk">
        <div className="app-pos-bg">
          <div className="app-container">
            <div className="app-blog-header">
              <img src={header} alt="Blogs" />
              <div className="app-wrap-cover-page">
                <h2>Blogs</h2>
                <p>Marketing &amp; Technology: The Future Explained in 2000 Words</p>
                {/* <Link to="/">I want specialized SEO team</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-blogs">
        <div className="app-container">
          <div className="heading-blog-head">
            <h3>
              We try to share our industry learnings <br />
              through our blogs.
            </h3>
          </div>
          <div className="app-blogs-list">
            <div className="app-blog-item">
              <span><img src={blog1} alt="How modern real estate agencies are shaping the industry" /></span>
              <div className="app-blog-desc">
                <Link to="/how-modern-real-estate-agencies-are-shaping-the-industry" className="head-blg">How modern real estate agencies are shaping the industry</Link>
                <div className="desc-blg">
                  <p>
                    Does your real estate business seem to be static with almost
                    minimal to no change? The real estate industry has started
                    seeing modern trends, shifting values...
                    <Link to="/how-modern-real-estate-agencies-are-shaping-the-industry">more</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="app-blog-item">
              <span><img src={blog2} alt="Role of AI-powered chatbots for customer service post Covid-19" /></span>
              <div className="app-blog-desc">
                <Link to="/role-of-ai-powered-chatbots-for-customer-service-post-covid-19" className="head-blg">Role of AI-powered chatbots for customer service post Covid-19</Link>
                <div className="desc-blg">
                  <p>
                    Owing to the ongoing COVID-19 pandemic, when businesses are
                    trying to open up new-age technologies, customers have
                    become more reliant on self-support...
                    <Link to="/role-of-ai-powered-chatbots-for-customer-service-post-covid-19">more</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="app-blog-item">
              <span><img src={blog3} alt="Everything you need to know about surrogate advertising" /></span>
              <div className="app-blog-desc">
                <Link to="/everything-you-need-to-know-about-surrogate-advertising" className="head-blg">Everything you need to know about surrogate advertising</Link>
                <div className="desc-blg">
                  <p>
                    The concept of advertising is nothing new in the present
                    age. All entrepreneurs know that without the most effective
                    advertising campaign, they cannot...
                    <Link to="/everything-you-need-to-know-about-surrogate-advertising">more</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="app-blog-item">
              <span><img src={blog4} alt="Upskilling Talents - Trend going forward for organizations?" /></span>
              <div className="app-blog-desc">
                <Link to="/upskilling-talents-trend-going-forward-for-organizations" className="head-blg">Upskilling Talents - Trend going forward for organizations?</Link>
                <div className="desc-blg">
                  <p>
                    Human resources are not merely a support for a company. They
                    have turned out to be strategic assets for any business.
                    But, why should you consider this...
                    <Link to="/upskilling-talents-trend-going-forward-for-organizations">more</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
    </Layout>
  );
}
