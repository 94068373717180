import React from "react";
import { Link } from "react-router-dom";
import togatherImg from "../../../images/ct-img.webp";

export default function Worktogather() {
  return (
    <div className="app-work-togather">
      <div className="app-container">
        <div className="app-inside-togather">
          <div className="app-left-togather">
            <img src={togatherImg} alt="contact us" />
          </div>
          <div className="app-right-togather">
            <h3>Let's create something Cool together.</h3>
            <p>Get in touch with us for a quick quote</p>
            <div className="app-str-btn">
              <Link to="/contact-us">Start a Project</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
