import React, { useState } from "react";
import { basepath } from "../core/config";

export default function Register() {
  const [initValue, setInitValue] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    event.preventDefault();
    setInitValue({
      ...initValue,
      [event.target.name]: event.target.value,
    });
  };

  const registration = async (formData) => {
    const configdata = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    fetch(`${basepath}/users/register`, configdata)
      .then((res) => res.json())
      .then((res) => {
        if (res && res.token) {
          localStorage.setItem("authToken", res.token);
        }
        window.location.href = "/admin/dashboard";
      })
      .catch((err) => console.log(err));
  };

  const handleRegister = () => {
    let getFormData = {
      name: initValue.name,
      email: initValue.email,
      password: initValue.password,
    };
    registration(getFormData);
  };

  return (
    <div className="app-backend">
      <div className="app-wrapper-cover-backend">
        <p>Register a new account</p>
        <div className="app-formArea">
          <div className="app-frmField">
            <b>Name</b>
            <input
              type="text"
              name="name"
              onChange={(event) => handleChange(event)}
              value={initValue.name}
            />
          </div>
          <div className="app-frmField">
            <b>Email</b>
            <input
              type="email"
              name="email"
              onChange={(event) => handleChange(event)}
              value={initValue.email}
            />
          </div>
          <div className="app-frmField">
            <b>Password</b>
            <input
              type="password"
              name="password"
              onChange={(event) => handleChange(event)}
              value={initValue.password}
            />
          </div>
          <div className="app-frmField">
            <span onClick={() => handleRegister()}>Login</span>
          </div>
        </div>
      </div>
    </div>
  );
}
