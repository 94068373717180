import moment from 'moment';
import React, { useEffect, useState } from "react";
import { basepath } from "../core/config";
import Sidebar from "./Sidebar";

export default function Contact() {
  const [contactList, setContactList] = useState(null);

  const getContactList = async () => {
    const configdata = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
    };
    fetch(`${basepath}/contact`, configdata)
      .then((res) => res.json())
      .then((res) => setContactList(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getContactList();
  }, []);

  return (
    <div className="app-dashboard-wrapper">
      <Sidebar />
      <div className="app-dashboard-main-section">
        <div className="app-wrap-dash">
          <h4>Contact List</h4>
          <div className="app-dash-container">
            {contactList && contactList.length ? (
              <ul>
                <li className="app-dash-tab-head">
                  <span>Name</span>
                  <span>Email</span>
                  <span>Contact</span>
                  <span>Country</span>
                  <span>Interest</span>
                  <span>Organization</span>
                  <span>Message</span>
                  <span>Date</span>
                  {/* <span>Action</span> */}
                </li>
                {contactList.reverse().map((item) => (
                  <li key={item._id}>
                    <span>{item.name}</span>
                    <span>{item.email}</span>
                    <span>{item.contact}</span>
                    <span>{item.country}</span>
                    <span>{item.interest}</span>
                    <span>{item.organization}</span>
                    <span>{item.message}</span>
                    <span>{moment(item.createdAt).format('llll')}</span>
                    {/* <span></span> */}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="noData">No Data Available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
