import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import client1 from "../../../images/c-logo/c-logo-1.webp";
import client2 from "../../../images/c-logo/c-logo-2.webp";
import client3 from "../../../images/c-logo/c-logo-3.webp";
import client5 from "../../../images/c-logo/c-logo-5.webp";
import client4 from "../../../images/c-logo/c-logo-6.webp";

export default function HomeAbout() {
  const pathRef = useRef();

  useEffect(() => {
    let svgPathElement = pathRef.current;
    let length = svgPathElement.getTotalLength();
    svgPathElement.style.strokeDasharray = length;
    svgPathElement.style.strokeDashoffset = length;
    const lineAnimation = () => {
      let docElement = document.documentElement;
      let scrollpercent = (document.body.scrollTop + docElement.scrollTop + 700) / (docElement.scrollHeight - docElement.clientHeight);
      let draw = length * scrollpercent;
      svgPathElement.style.strokeDashoffset = length - draw * 9;
    };
    window.addEventListener("scroll", lineAnimation);
  }, []);

  return (
    <div className="app-home-about">
      <div className="app-container">
        <div className="app-cover-wrapper">
          <h2 className="app-home-cover-heading"><span>with</span> DigiPrism</h2>
          <h3>Market Your Brand as if it needs no marketing</h3>
          <p>We Digitally establish your brand the most customized way as your customers' consistent first choice.</p>
          <div className="app-important-link">
            <div className="app-impt-divide">
              <Link to="/search-engine-ranking">SEO</Link>
              <Link to="/search-engine-ranking">SEM</Link>
              <Link to="/social-media-marketing">Social Media</Link>
              <Link to="/">Email</Link>
              <Link to="/">Analytics</Link>
              <Link to="/influencer-and-affiliate-marketing">Influencer &amp; Affiliate</Link>
              <Link to="/content-marketing">Content Writing</Link>
              <Link to="/content-marketing">Proofreading</Link>
            </div>
            <div className="app-impt-divide">
              <Link to="/content-marketing">Content Management</Link>
              <Link to="/">Link Building</Link>
              <Link to="/designing-services">Graphic Designing</Link>
              <Link to="/">Business Consultation</Link>
              <Link to="/content-marketing">WhatsApp &amp; SMS Marketing</Link>
            </div>
            <div className="app-impt-divide">
              <Link to="/">Voice Search</Link>
              <Link to="/">Web Applications</Link>
              <Link to="/">Mobile App Development</Link>
              <Link to="/">E-Commerce Applications</Link>
            </div>
          </div>
          <b>Trusted by</b>
          <ul>
            <li><img src={client1} alt="The Blue French Horn" /></li>
            <li><img src={client2} alt="Find Your Pie" /></li>
            <li><img src={client3} alt="Lenceria" /></li>
            <li><img src={client4} alt="My Nirmaan" /></li>
            <li><img src={client5} alt="Wethnic Wind" /></li>
          </ul>
          <div className="app-info-slide-mobile">
            <Link to="/">
              Here's Your <span>Free Website Audit Report</span>
            </Link>
          </div>
          <div className="app-line-anim">
            <svg width="284" height="490" viewBox="0 0 284 490" fill="none"><path ref={pathRef} stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" d="M137.49 2.60001C183.51 7.94001 229.26 28.58 257.12 65.6C284.98 102.61 291.23 157.14 265.45 195.64C252.73 214.65 233.76 228.41 215.52 242.22C193.25 259.09 171.39 276.72 152.66 297.45C137.62 314.09 124.03 336.75 131.65 357.85C137.24 373.31 153.11 383.05 169.18 386.57C180.53 389.05 193.28 388.76 202.68 381.92C212.08 375.08 215.95 360.19 208.28 351.46C204.81 347.51 199.7 345.4 194.61 344.07C173.91 338.65 151.3 344.3 133.5 356.18C115.7 368.06 102.31 385.64 92.29 404.55C81.88 424.2 74.6 445.8 60.56 463.04C46.53 480.28 22.76 492.46 2 484.51" /></svg>
          </div>
        </div>
      </div>
    </div>
  );
}
