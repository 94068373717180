import React from "react";
import { Link } from "react-router-dom";
import twitter from "../../../images/twitter-small.svg";

export default function Footer() {
  return (
    <div className="app-wrapper-footer">
      <div className="app-container">
        <div className="app-footer-wrap">
          <div className="app-footer-left">
            <div className="app-twitter-feed">
              <div className="app-tweet-heading"><img src={twitter} alt="twitter" /> Tweet Tweet</div>
              <div className="app-tweet-content">
                <p>
                  New site live for co&amp;co - a straight-talking marketing
                  agency: https://t.co/1xojgUjJN6 https://t.co/pOGEo111ZJ
                </p>
              </div>
            </div>
            <div className="app-twitter-feed">
              <div className="app-tweet-heading">Quick Contact</div>
              <div className="app-tweet-content">
                <p>Phone: &nbsp;&nbsp;<a href="tel:7890919225">+91 7890919225</a></p>
                <p>Email: &nbsp;&nbsp;<a href="mailto:support@digiprism.in">support@digiprism.in</a></p>
              </div>
            </div>
            <div className="app-twitter-feed">
              <div className="app-tweet-content">
                <p>&copy; Made with <span></span> Digiprism 2020. All Rights Reserved.</p>
              </div>
            </div>
          </div>
          <div className="app-footer-right">
            <div className="app-left-footer">
              <div className="app-footer-cover-item">
                <div className="app-cover-heading">Services</div>
                <ul>
                  <li><Link to="/search-engine-ranking">- Search Engine Ranking (SEO &amp; PPC)</Link></li>
                  <li><Link to="/social-media-marketing">- Social Media Marketing</Link></li>
                  <li><Link to="/designing-services">- Designing Services</Link></li>
                  <li><Link to="/content-marketing">- Content Marketing</Link></li>
                  <li><Link to="/influencer-and-affiliate-marketing">- Influencer &amp; Affiliate marketing</Link></li>
                  <li><Link to="/online-reputation-management">- Online Reputation Management</Link></li>
                  <li><Link to="/growth-hacking">- Growth Hacking</Link></li>
                  <li><Link to="/web-app-developments">- Web App Development</Link></li>
                </ul>
              </div>
              <div className="app-footer-cover-item">
                <div className="app-cover-heading">Policies</div>
                <ul>
                  <li><Link to="/privacy-policy">- Privacy Policy</Link></li>
                  <li><Link to="/cookies-policy">- Cookies Policy</Link></li>
                  <li><Link to="/terms-and-condtions">- Terms &amp; Condtions</Link></li>
                </ul>
              </div>
            </div>
            <div className="app-left-footer">
              <div className="app-footer-cover-item">
                <div className="app-cover-heading">Industries</div>
                <ul>
                  <li><Link to="/lifestyle-and-personal-care">- Lifestyle &amp; Personal Care</Link></li>
                  <li><Link to="/retail-and-ecommerce">- Retail &amp; Ecommerce</Link></li>
                  <li><Link to="/health-supplement">- Health Supplement</Link></li>
                  <li><Link to="/insurance">- Insurance</Link></li>
                  <li><Link to="/textile-and-garments">- Textile &amp; garments</Link></li>
                  <li><Link to="/automobile">- Automobile</Link></li>
                  <li><Link to="/real-estate">- Real Estate</Link></li>
                  <li><Link to="/book-release">- Book Release</Link></li>
                  <li><Link to="/precious-and-semi-precious-jewelry">- Precious Jewelry</Link></li>
                  <li><Link to="/hospitality">- Hospitality Industry</Link></li>
                  <li><Link to="/medical-facility">- Medical Facility</Link></li>
                  <li><Link to="/consumer-products-and-fmcg">- Consumer Products &amp; FMCG</Link></li>
                </ul>
              </div>
              <div className="app-footer-cover-item"></div>
              <div className="app-footer-cover-item"></div>
            </div>
            <div className="app-left-footer">
              <div className="app-footer-cover-item">
                <div className="app-cover-heading">Who We Are</div>
                <ul>
                  <li><Link to="/about-us">- About Us</Link></li>
                  <li><Link to="/our-team">- Our Team</Link></li>
                  {/* <li><Link to="/join-us">- Career</Link></li> */}
                  <li><Link to="/contact-us">- Contact Us</Link></li>
                </ul>
              </div>
              <div className="app-footer-cover-item">
                <div className="app-cover-heading">Explore</div>
                <ul>
                  <li><Link to="/case-study">- Case Study</Link></li>
                  <li><Link to="/blogs">- Blogs</Link></li>
                  {/* <li><Link to="/">- Whitepaper</Link></li>
                  <li><Link to="/">- Newsletter</Link></li>
                  <li><Link to="/">- Podcast</Link></li> */}
                </ul>
              </div>
              <div className="app-footer-cover-item"></div>
            </div>
          </div>
        </div>
      </div>
      <span className="app-last-logo">digiprism</span>
    </div>
  );
}
