import React from "react";
import { Helmet } from "react-helmet";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from 'react-router-dom';
import caseImg from "../../../images/casestudy/cs-healthsupplement.png";
import img1 from "../../../images/industry-banners/top/7-1.jpg";
import img2 from "../../../images/industry-banners/top/7-2.jpg";
import img3 from "../../../images/industry-banners/top/7-3.jpg";
import Layout from "../../core/Layout";
import CaseStudyPopup from "../services/CaseStudyPopup";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function HealthSupplement() {
  window.scrollTo(0, 0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const openCaseStudyPopup = () => {
    setOpenPopup(!openPopup);
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>DigiPrism | Digital Marketing for Health Supplement</title>
        <meta name="description" content="Unaware Target Audience? Stiff Competition? Let's Solve With Content Marketing, Webinars and Live-stream, and SEO. Book A Free Demo Now!" />
      </Helmet>
      <div className="app-banner-indus health">
        <div className="app-container">
          <div className="app-inside-wrapper">
            <h2>
              DigiPrism <span>Health Supplement</span>
            </h2>
            <p>
              Unaware Target Audience, Customer Hesitation &amp; Uncontrolled
              <br />Pricing the Top 3 Battles We Have Fought Digitally for Health <br />Supplement
              Industry the most.
            </p>
            <p>What Can We Start Solving With?</p>
            <Link to="/contact-us" className="app-tell-us">Get A Free Consultation</Link>
          </div>
        </div>
      </div>
      <div className="app-indust-head-all">
        <h3>Your Problems... Sorted!</h3>
      </div>
      <div className="app-left-industry-area health">
        <div className="app-container">
          <div className="app-flx-wrapp">
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>1.</span>
                Low website traffic
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>Why this happens?</h6>
                  <ul>
                    <li>Outdated information</li>
                    <li>Bad UX</li>
                    <li>Error 404: Page Not Found</li>
                    <li>No data-driven marketing</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Content audit</li>
                    <li>UI/UX redesigning</li>
                    <li>Broken links solved</li>
                    <li>Google Analytics setup</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>2.</span>
                Low customer response rate
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>Why this happens?</h6>
                  <ul>
                    <li>Low email open rate</li>
                    <li>No engaging social media presence</li>
                    <li>No blogging consistency </li>
                    <li>Irregular communications</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Proper email scheduling</li>
                    <li>Video and photo sharing on social media</li>
                    <li>Content marketing</li>
                    <li>Customer satisfaction survey conducted</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>3.</span>Sales rate getting low
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>Why this happens?</h6>
                  <ul>
                    <li>Shopping carts abandoned</li>
                    <li>High bounce rate</li>
                    <li>Complicated interface design</li>
                    <li>No customer-focused marketing </li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Simplified checkout page</li>
                    <li>Conversion rate optimization </li>
                    <li>CTA and signup process reviewed</li>
                    <li>Comprehensive consumer behavior analysis</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>4.</span>Lead generation problems
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>Why this happens?</h6>
                  <ul>
                    <li>Outdated keyword data</li>
                    <li>No social proof</li>
                    <li>Unappealing landing pages</li>
                    <li>Focusing only on sales</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Long and short-tail keyword analysis</li>
                    <li>Adding customer testimonial page </li>
                    <li>Landing page redesigning</li>
                    <li>Multichannel Marketing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-three-channel">
        <div className="app-container">
          <div className="app-top-heading-cov health">
            <h3>
              Top 3 Marketing Channels for <br />
              <span>Health Supplement Industry</span>
            </h3>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Internet of Things</h4>
                <p>
                  IoT solutions close gap between physical and digital data
                  sources by tracking customers’ behaviors. They facilitate
                  traceability and ensure safety to health supplements. The
                  right use of IoT applications ensures operational and
                  financial augmentation of your business. IoT also simplifies
                  data collection and help with decision making.
                </p>
              </div>
              <span className="app-topright"><img src={img1} alt="Internet of Things" /></span>
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M5 1C73 9 141 78.5 130 183M130 183L141 170.5M130 183L119 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="-0.00012207"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-rgt">
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <span className="app-topleft"><img src={img2} alt="Webinars &amp; Livestream" /></span>
              <div className="app-topright">
                <h4>Webinars &amp; Livestream</h4>
                <p>
                  Regular webinars and live streaming help in obtaining
                  real-time feedback. The best webinar content showcases
                  problems and delivers superb value. It also promotes in-person
                  conversations and audience engagement. Generate new leads and
                  build relationship with live video streaming. Webinars give a
                  chance of increasing business credibility.
                </p>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Conversational Marketing</h4>
                <p>
                  Conversation marketing is a feedback-oriented solution
                  promoting two-way communication. Potential customers get
                  authentic experience from real-time conversations. This
                  marketing ensures a smooth journey of potential customers
                  through your sales channel. It helps in avoiding the interest
                  loss risks during the lead qualifying process.
                </p>
              </div>
              <span className="app-topright"><img src={img3} alt="Conversational Marketing" /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="app-scroll-items-cover wrapp-tree-one">
        <div className="app-container">
          <div className="app-scrl-wrapper">
            <div className="app-spl-latest-left">
              <span>
                <img src={caseImg} alt="case study" />
                <div className="download-btn" onClick={() => openCaseStudyPopup()}>Download</div>
              </span>
              <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                <g filter="url(#filter0_d)">
                  <path
                    d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="6 7.9"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="-9.15527e-05"
                    width="146"
                    height="192"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <p><Link to="/case-study">More Case Study</Link></p>
            </div>
            <div className="app-spl-latest-right">
              <span>
                <svg viewBox="0 0 351.128 351.128">
                  <path d="M72.326,147.33c4.284-26.928,37.944-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.224-0.612,2.448-1.224,3.06-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.976,11.628C38.054,85.518,1.946,136.313,3.782,184.662c-6.12,32.437-4.896,67.32,4.284,96.084c6.12,18.36,23.868,27.54,42.228,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.524-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C115.778,165.078,66.818,181.602,72.326,147.33z" />
                  <path d="M274.286,147.33c4.284-26.928,37.943-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.225-0.612,2.448-1.224,3.061-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.977,11.628c-35.496,29.376-71.604,80.172-69.768,128.52c-6.12,32.437-4.896,67.32,4.283,96.084c6.12,18.36,23.868,27.54,42.229,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.523-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C317.738,165.078,268.166,181.602,274.286,147.33z" />
                </svg>
              </span>
              <h6>
                We are very impressed with the work. You have delivered what we
                were looking for..
              </h6>
              <b>Subham Mohanty,</b>
              <p>(CEO &amp; Founder) - My Nirmaan.</p>
              <div className="app-bg-dot">
                <svg viewBox="0 0 266 246" fill="none">
                  <circle cx="3" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="63" r="3" fill="#F7F7F7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-faq-cover">
        <div className="app-container">
          <div className="app-faq-wrap">
            <div className="app-top-heading-cov health">
              <h3>
                Frequently Asked <span>Questions</span>
              </h3>
            </div>
            <div className="app-faq-wrapper health">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      We have sold a recently launched protein supplement to
                      some customers. How will I get their feedback?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We think that the best way of connecting them is by
                      starting an Email Marketing campaign. While applying our
                      email personalization, we can send emails automatically
                      and manually.
                    </p>
                    <p>
                      Whatsapp marketing can be a very good option for
                      post-purchase follow-ups and feedback generation. Online
                      reward chains also work very well in these cases.
                    </p>
                    <p>
                      Moreover, our professionals integrate a CTA and write
                      Dynamic Email Content in a customer-friendly tone to
                      stimulate recipients to send responses.  Besides, we help
                      you with Promotional Campaign, Post-Purchase Drip
                      Campaign, Connect-Via-Social Campaign, and a Triggered
                      Email Series.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have released a better version of one of my health
                      supplements. Can you help me to advertise it?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      When you have social media pages, we are here to leverage
                      the best value from them for your health supplement
                      advertising campaign. Our Slideshow Ads, Stories Ads,
                      Carousel Ads, and Video-Only Ads will promote your
                      products to the correct audience at the correct time.
                    </p>
                    <p>
                      Using the News Feeds Section of Facebook, we will give
                      constant updates about your new products. Besides, with
                      the help of Instagram Marketing, we make the best use of
                      images. Moreover, we also rely on In-Stream Ads and Bumper
                      Ads to market your new product. We check the Clickthrough
                      Rate and measure the success of our campaign.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have a medium-sized manufacturing business on
                      health-related products. How will I increase my online
                      visibility?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We think that Search Engine Marketing and Search Engine
                      Optimization are the most effective solutions. With the
                      proper Contextual Advertising, we will increase your
                      revenues and minimize Customer Acquisition Cost. Moreover,
                      our Search Retargeting program enables you to know the
                      visitor's search history.
                    </p>
                    <p>
                      Besides, our special techniques for Keyword Research and
                      Keyword Marketing will help you to present your brand in
                      front of the correct target audience.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I am a startup as a health product selling company. Is
                      there any low-budget solution to promote my business
                      online?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Pay-Per-Click Advertising is the most cost-effective and
                      invaluable way of marketing your company where your posts
                      will be shown only to people who are searching for types
                      of products that you sell.
                    </p>
                    <p>
                      Our ad copywriters are here to compose search-engine
                      friendly content for Google Ads and Bing Ads to ensure a
                      high level of search traffic to your site. You will pay
                      only for the number of clicks on those ads.
                    </p>
                    <p>
                      Moreover, with Google Shopping adverts, Sequential
                      Remarketing, and Display Advertising, we strengthen your
                      brand visibility.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have found lots of customers using mobiles to buy health
                      supplements. How will I draw customers from mobile
                      platforms?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      First, let us know if you have a mobile app and a
                      responsive website. We will make your mobile platform
                      SEO-friendly. Our professionals can help you with
                      location-based campaigns to let you get traffic from the
                      target locations. Moreover, we write dynamic content for
                      App Marketing. Besides, our Mobile Push Notifications and
                      Mobile-first Ad campaigns some of the effective tricks to
                      draw customers.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I need to beat my stronger competitors who sell healthcare
                      products. How will you help me?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We need to make a Competitor Analysis before starting a
                      marketing campaign. Our professionals check out the direct
                      competition, indirect competition, and market contraction
                      related to your products. During the analysis, we obtain
                      information on your competitors’ product prices, pros, and
                      cons of their products, potential risks, company culture,
                      and customer experience. The analytical result enables us
                      to drive more leads, increase conversions, and raise
                      revenues.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Show me a detailed Health Supplement success story.
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="divCls">
                      <b>About the company</b>
                      This company manufactures and sells a range of health
                      supplements for sportsmen and fitness-conscious persons.
                      However, due to the high competitiveness in this market,
                      it was not easy for the brand to beat its competitors.
                      Despite providing free products and investing in TV ads,
                      the company encountered problems in increasing the number
                      of consumers. Our digital marketers, graphic designers,
                      and web developers worked together to elevate brand
                      status. Presently, this health supplement company has
                      established its presence in most of the cities in India.
                    </p>
                    <div className="divCls">
                      <b>Condition before starting our marketing campaign</b>
                      <ul>
                        <li>
                          As the website design was not responsive, there is
                          very low mobile traffic.
                        </li>
                        <li>
                          The SEO efforts were not based on the latest Google
                          algorithms.
                        </li>
                        <li>
                          The company invested only in offline brochures and
                          leaflets to spread its name.
                        </li>
                        <li>It had no social media page and no followers.</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Problem statement</b>
                      <ul>
                        <li>Online presence was not strong</li>
                        <li>Link building campaign not started</li>
                        <li>SEO penalty for duplicate content</li>
                        <li>
                          Visitors leaving the website without purchasing
                          products
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Objective/KPI</b>
                      <ul>
                        <li>To develop quality and high-end website</li>
                        <li>
                          To promote the brand with local and national SEO
                        </li>
                        <li>To attract more leads to the site</li>
                        <li>
                          To increase the sales rate of all categories of health
                          supplements
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>How we reached our targets</b>
                      <b>Audited the website for a responsive design</b>
                      <p>
                        Our developers and other professionals closely inspected
                        the website. We found that mobile visitors could not
                        scroll and zoom its webpages easily. That is why we had
                        gone through some steps to solve it
                      </p>
                      <ul>
                        <li>
                          We started with grid adjustment by revising the site’s
                          CSS and other codes.
                        </li>
                        <li>
                          Then, we organized the menu to make it fit smaller
                          screens.
                        </li>
                        <li>
                          We also optimized images to alleviate bandwidth and
                          scaling issues.
                        </li>
                        <li>
                          With a mobile-first approach, we worked on logos and
                          other visual elements.
                        </li>
                        <li>
                          Lastly, we used media queries to know how the content
                          responds to different devices.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Search Engine Optimization</b>
                      <p>
                        The company was not receiving the best returns from its
                        SEO efforts. The end goal was to increase organic
                        traffic and remove the penalty. We had published fresh
                        content and gone through lots of steps.
                      </p>
                      <p>
                        We found the search intent of the target users and
                        worked on keywords. Basically, we added keywords to
                      </p>
                      <ul>
                        <li>Meta description</li>
                        <li>Page title</li>
                        <li>Content in location-based webpages-</li>
                        <li>H1,H2 and other headings </li>
                      </ul>
                      <p>Moreover, our team had gone further to</p>
                      <ul>
                        <li>
                          Use Google Pagespeed Insights for faster loading
                        </li>
                        <li>Compress image sizes</li>
                        <li>Minimized useless HTML</li>
                        <li>Alt tags for image files</li>
                        <li>
                          Sprinkled keywords throughout the content to maintain
                          consistency
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Lead generation and lead conversion campaigns</b>
                      <p>
                        From our continuous analysis, we found that the sales
                        funnel of the company was not receiving qualified leads.
                        Ultimately, there was a very low rate of lead
                        conversion.
                      </p>
                      <p>
                        {" "}
                        Have a look at what we had done for the lead generation
                        campaign.
                      </p>
                      <ul>
                        <li>
                          Our campaign started with a search for marketing
                          qualified, sales qualified, and product qualified
                          leads.
                        </li>
                        <li>
                          We leveraged different channels, like the company
                          website, social media pages, and blogs to attract
                          leads.
                        </li>
                        <li>
                          Besides, we used lead database platforms to find the
                          qualified leads with accurate details.
                        </li>
                        <li>
                          With an attractive button and a compelling message, we
                          designed CTAs to integrate into these channels.
                        </li>
                        <li>
                          Moreover, with automated email marketing, retargeting,
                          referral marketing, and landing page optimization, we
                          generated lots of leads.{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Link building campaign</b>
                      <p>
                        To build authority with backlinks was the motto of our
                        team. By placing clickable anchor text in a proper way,
                        we focused on both inbound and outbound links.
                      </p>
                      <ul>
                        <li>
                          While adding links to blog content, we have also
                          inserted them to visual assets, like infographics, and
                          images.
                        </li>
                        <li>
                          Our team had chosen high DA websites to submit posts
                          with highly linkable and sharable content.
                        </li>
                        <li>
                          What’s more, we put our clients’ products in front of
                          popular bloggers, influencers, and social media users.
                        </li>
                      </ul>
                      <p>Other efforts that had driven success to the brand</p>
                      <ul>
                        <li>
                          Grouped keywords and assigned them to the content
                        </li>
                        <li>
                          Created a search-friendly information architecture
                        </li>
                        <li>Made intelligent inter-linking</li>
                        <li>Removed spammy and unnatural links</li>
                        <li>Focused on manual outreach links</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Outcome</b>
                      <p>
                        We like to sum up what we achieved in the past 6 months
                        and how we benefited the health supplement manufacturing
                        company. Here is our success presented in bullets.
                      </p>
                      <ul>
                        <li>More than 400% increase in mobile traffic</li>
                        <li>500+ contact form submissions every day</li>
                        <li>
                          200% increase in backlinks by sending cold emails and
                          posting blogs
                        </li>
                        <li>45% increase in organic traffic</li>
                        <li>80% increase in social media followers</li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {openPopup && <CaseStudyPopup openCaseStudyPopup={openCaseStudyPopup} />}
    </Layout>
  );
}
