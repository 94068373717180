import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper.scss";
import work4 from "../../../images/case/work-1.webp";
import work1 from "../../../images/case/work-10.webp";
import work11 from "../../../images/case/work-11.webp";
import work12 from "../../../images/case/work-12.webp";
import work5 from "../../../images/case/work-2.webp";
import work6 from "../../../images/case/work-3.webp";
import work7 from "../../../images/case/work-4.webp";
import work3 from "../../../images/case/work-5.webp";
import work8 from "../../../images/case/work-6.webp";
import work9 from "../../../images/case/work-7.webp";
import work10 from "../../../images/case/work-8.webp";
import work2 from "../../../images/case/work-9.webp";

const data = [
  {
    id: "1",
    image: work1,
    link: "/lifestyle-and-personal-care",
    title: "Lifestyle & Personal Care", 
    alt: "Lifestyle & Personal Care" 
  },
  {
    id: "2",
    image: work2,
    link: "/textile-and-garments",
    title: "Textile & Garments", 
    alt: "Textile & Garments" 
  },
  {
    id: "3",
    image: work3,
    link: "/medical-facility",
    title: "Medical Facility", 
    alt: "Medical Facility" 
  },
  {
    id: "4",
    image: work4,
    link: "/automobile",
    title: "Automobile", 
    alt: "Automobile" 
  },
  {
    id: "5",
    image: work5,
    link: "/insurance",
    title: "Insurance", 
    alt: "Insurance" 
  },
  {
    id: "6",
    image: work6,
    link: "/hospitality",
    title: "Hospitality", 
    alt: "Hospitality" 
  },
  {
    id: "7",
    image: work7,
    link: "/real-estate",
    title: "Real Estate", 
    alt: "Real Estate" 
  },
  {
    id: "8",
    image: work8,
    link: "/retail-and-ecommerce",
    title: "Retail & Ecommerce", 
    alt: "Retail & Ecommerce" 
  },
  {
    id: "9",
    image: work9,
    link: "/health-supplement",
    title: "Health Supplement", 
    alt: "Health Supplement" 
  },
  {
    id: "10",
    image: work10,
    link: "/book-release",
    title: "Book Release", 
    alt: "Book Release" 
  },
  {
    id: "11",
    image: work11,
    link: "/precious-and-semi-precious-jewelry",
    title: "Precious & Semi-Precious Jewelry", 
    alt: "Precious & Semi-Precious Jewelry" 
  },
  {
    id: "12",
    image: work12,
    link: "/consumer-products-and-fmcg",
    title: "Customer product & FMCG", 
    alt: "Customer product & FMCG" 
  },
]

SwiperCore.use([Navigation, Pagination]);
export default function HomeWorks() {
  const pathRef = useRef();
  useEffect(() => {
    let svgPathElement = pathRef.current;
    let length = svgPathElement.getTotalLength();
    svgPathElement.style.strokeDasharray = length;
    svgPathElement.style.strokeDashoffset = length;
    const lineAnimation = () => {
      let docElement = document.documentElement;
      let scrollpercent = (document.body.scrollTop + docElement.scrollTop + 700) / (docElement.scrollHeight - docElement.clientHeight);
      let draw = length * scrollpercent;
      svgPathElement.style.strokeDashoffset = length - draw * 9.2;
    };
    window.addEventListener("scroll", lineAnimation);
  }, []);

  return (
    <div className="app-work-home-cover">
      <div className="app-container">
        <div className="app-cover-home port-cover">
          <h4>success <span>Stories</span></h4>
          <div className="app-work-left">
            <Swiper navigation spaceBetween={20} breakpoints={{480: { slidesPerView: 1, }, 1024: { slidesPerView: 2, }, 1600: { slidesPerView: 3, }}} pagination={{ clickable: true, type: "progressbar" }}>
              {data.map(item => 
                <SwiperSlide key={item.id}>
                  <Link to={item.link} className="app-wrk-item">
                    <div className="app-work-cov-img"><img src={item.image} alt={item.alt} /></div>
                    <p>{item.title} <span>→</span></p>
                  </Link>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
          <div className="app-anim-last">
            <svg width="111" height="291" viewBox="0 0 111 291" fill="none">
              <path ref={pathRef} stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" d="M43.0001 2C102 61 154 157 43.0001 157C-39.7345 157 33 242 16.0001 289" />
            </svg>
          </div>
        </div>
      </div>
      <div className="app-circle">
        <div className="app-cir-wrap">
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
          <div className="c"></div>
        </div>
      </div>
    </div>
  );
}
