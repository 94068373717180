import moment from 'moment';
import React, { useEffect, useState } from "react";
import { basepath } from "../core/config";
import Sidebar from "./Sidebar";

export default function BasicInfo() {
  const [basicInfo, setBasicInfo] = useState(null);

  const getBasicInfo = async () => {
    const configdata = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
    };
    fetch(`${basepath}/basicinfo`, configdata)
      .then((res) => res.json())
      .then((res) => setBasicInfo(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBasicInfo();
  }, []);

  return (
    <div className="app-dashboard-wrapper">
      <Sidebar />
      <div className="app-dashboard-main-section">
        <div className="app-wrap-dash">
          <h4>Basic Information</h4>
          <div className="app-dash-container brochure">
            {basicInfo && basicInfo.length ? (
              <ul>
                <li className="app-dash-tab-head">
                  <span>Name</span>
                  <span>Email</span>
                  <span>Mobile</span>
                  <span>Country</span>
                  <span>Website</span>
                  <span>Business</span>
                  <span>From Where</span>
                  <span>Date</span>
                </li>
                {basicInfo.reverse().map((item) => (
                  <li key={item._id}>
                    <span>{item.name}</span>
                    <span>{item.email}</span>
                    <span>{item.mobile}</span>
                    <span>{item.country}</span>
                    <span>{item.website}</span>
                    <span>{item.business}</span>
                    <span>{item.fromWhere}</span>
                    <span>{moment(item.createdAt).format('llll')}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="noData">No Data Available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
