import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import imp4 from "../../../images/compliance.svg";
import lead from "../../../images/leadership.webp";
import linkedin from '../../../images/linkedin.png';
import imp2 from "../../../images/medical.svg";
import monalisa from "../../../images/monalisha.webp";
import imp1 from "../../../images/payroll.svg";
import pie1 from '../../../images/piemf.webp';
import pie2 from '../../../images/piesec.webp';
import priya from "../../../images/priya.webp";
import rajib from "../../../images/rajib.webp";
import soutrik from "../../../images/soutrik.webp";
import imp5 from "../../../images/support.svg";
import tithi from "../../../images/tithi.webp";
import imp3 from "../../../images/tracking.svg";
import Layout from "../../core/Layout";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";


export default function Leadership() {
  window.scrollTo(0, 0);
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>Our Team | DigiPrism Marketing Consultancy</title>
        <meta name="description" content="India’s First Industry-Specific Digital Marketing Consultancy | CEO Rajib Banerjee | MD Monalisa Chowdhury | 36 Members Team | 12 Business Domains" />
      </Helmet>
      <div className="app-lead-bg">
        <div className="app-container">
          <div className="app-services-header">
            <img src={lead} alt="Leadership" />
            <div className="app-wrap-cover-page">
              <h2>Our Team</h2>
              <p>
                We understood the importance of Sales Team when <br />
                Mr. Matthew gave us our first project; Development team <br />
                when clients said, ‘can’t take risk of payment gateway with <br />
                any other agency but DigiPrism’; Marketing team when aspiring <br />
                entrepreneurs messages that they wanna be like us one day;  <br />
                Finance team when employees can plan next month’s expenditure <br />
                with conviction.
              </p>
              <p>
                We know, setting us DigiPrism is the best decision we have ever
                taken.
              </p>
              <Link to="/contact-us">Connect with us</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="app-leader-top-view-area">
        <div className="app-container">
          <div className="app-leader-head-right">
            <h3>A Successful Business <br />Starts with a Successful Team.</h3>
            <ul>
              <li><span><img src={imp1} alt="icon" /></span><p>Full Services Payroll</p></li>
              <li><span><img src={imp2} alt="icon" /></span><p>Medical Facilities</p></li>
              <li><span><img src={imp3} alt="icon" /></span><p>Time Tracking and PTO</p></li>
              <li><span><img src={imp4} alt="icon" /></span><p>Compliance Help</p></li>
              <li><span><img src={imp5} alt="icon" /></span><p>Friendly Support</p></li>
            </ul>
          </div>
          <div className="app-pie-list">
            <h3>Our Successful Story Analytics.</h3>
            <ul>
              <li><img src={pie1} alt="icon" /></li>
              <li><img src={pie2} alt="icon" /></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="app-leader-item-wrap">
        <div className="app-container">
          <h3>Meet The Leadership &amp; Team</h3>
          <div className="app-wrap-leader">
            <div className="app-leader-item">
              <div className="flip-card">
                <div className="flip-front">
                  <span><img src={rajib} alt="Rajib Banerjee" /></span>
                  <div className="app-lead-descpt">
                    <h3>Rajib Banerjee</h3>
                    <b>Co-Founder &amp; CEO</b>
                  </div>
                </div>
                <div className="flip-back">
                  <div className="app-lead-descpt">
                    <h3>Rajib Banerjee</h3>
                    <b>Co-Founder &amp; CEO</b>
                    <p>
                      An industrious and successful <b>IT Leader with 17+ years</b>{" "}
                      of distinguished footprint in large scale IT Global portfolio.
                      For almost a couple of decades now Mr. Banerjee has been
                      associated in various capacities for multiple domains and
                      industries like IT, CPG/FMCG, Retail, Pharma Healthcare &amp;
                      Business Consultancy. He has a proven track record and is an
                      expert in Business Strategy, Project and Program Management,
                      Solution &amp; Service delivery of IT solutions, Sales and
                      Marketing &amp; Leadership, and Manpower Development.
                    </p>
                    <a href="https://www.linkedin.com/in/rajib-banerjee/" target="_blank" rel='noopener noreferrer'><img src={linkedin} alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-leader-item">
              <div className="flip-card">
                <div className="flip-front">
                  <span><img src={monalisa} alt="Monalisa Chowdhury" /></span>
                  <div className="app-lead-descpt">
                    <h3>Monalisa Chowdhury</h3>
                    <b>Co-Founder &amp; MD</b>
                  </div>
                </div>
                <div className="flip-back">
                  <div className="app-lead-descpt">
                    <h3>Monalisa Chowdhury</h3>
                    <b>Co-Founder &amp; MD</b>
                    <p>
                      Official selection of{" "}
                      <b>‘National Woman Entrepreneurship, 2020'</b>. A Digital
                      Marketing Strategist for 6 years; Monalisa is a well-known
                      name in the blogging industry who has devoted her 9 to 5 (read
                      it 24/7/365) to pulling brands up from varied chasms of the
                      incomplete customer journey.
                    </p>
                    <p>
                      Monalisa has seen start-up babies take birth in the
                      developer's labor room; completed their preschool; helped them
                      to graduate; now they are spotted with the industry leaders.
                    </p>
                    <a href="https://www.linkedin.com/in/monalisa-chowdhury/" target="_blank" rel='noopener noreferrer'><img src={linkedin} alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="app-wrap-leader three-leader">
            <div className="app-leader-item">
              <div className="flip-card">
                <div className="flip-front">
                  <span><img src={tithi} alt="Tithi Biswas" /></span>
                  <div className="app-lead-descpt">
                    <h3>Tithi Biswas</h3>
                    <b>Head of Content</b>
                  </div>
                </div>
                <div className="flip-back">
                  <div className="app-lead-descpt">
                    <h3>Tithi Biswas</h3>
                    <b>Head of Content</b>
                    <p>It's Been 7 Years and 3 Companies Tithi Has Benn Handling the Content Management Verticle. Joined DigiPrism 8 Months Back. There's No Look-Back.</p>
                    {/* <a href="/" target="_blank" rel='noopener noreferrer'><img src={linkedin} alt="" /></a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="app-leader-item">
              <div className="flip-card">
                <div className="flip-front">
                  <span><img src={soutrik} alt="Soutrik Mukherjee" /></span>
                  <div className="app-lead-descpt">
                    <h3>Soutrik Mukherjee</h3>
                    <b>Head of Engineering</b>
                  </div>
                </div>
                <div className="flip-back">
                  <div className="app-lead-descpt">
                    <h3>Soutrik Mukherjee</h3>
                    <b>Head of Engineering</b>
                    <p>Reigning at both highly-matrixed Fortune 500 companies and small start-ups, Joined DigiPrism A Year Back. DigiPrism Engineering is At Good Hands.</p>
                    {/* <a href="/" target="_blank" rel='noopener noreferrer'><img src={linkedin} alt="" /></a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="app-leader-item">
              <div className="flip-card">
                <div className="flip-front">
                  <span><img src={priya} alt="Priya Shaw" /></span>
                  <div className="app-lead-descpt">
                    <h3>Priya Shaw</h3>
                    <b>Marketing Manager</b>
                  </div>
                </div>
                <div className="flip-back">
                  <div className="app-lead-descpt">
                    <h3>Priya Shaw</h3>
                    <b>Marketing Manager</b>
                    <p>S. P. Jain Management Institute Graduate. Handling DigiPrism's Marketing Department from Day 1; Delivered 50+ Successful Projects So Far.</p>
                    {/* <a href="/" target="_blank"><img src={linkedin} alt="" /></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Worktogather />
      <Socialmedia />
    </Layout>
  );
}
