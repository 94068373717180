import React from "react";
import { Link } from "react-router-dom";

export default function ServicesLinks() {
  return (
    <div className="app-left-item-spcl">
      <h4>More Services</h4>
      <Link to="/social-media-marketing">→&nbsp; Social Media Marketing</Link>
      <Link to="/designing-services">→&nbsp; Designing Services</Link>
      <Link to="/content-marketing">→&nbsp; Content Marketing</Link>
      <Link to="/influencer-and-affiliate-marketing">→&nbsp; Influencer &amp; Affiliate marketing</Link>
      <Link to="/online-reputation-management">→&nbsp; Online Reputation Management</Link>
      <Link to="/growth-hacking">→&nbsp; Growth Hacking</Link>
      {/* <Link to="/">→ &nbsp;WhatsApp &amp; SMS Marketing</Link> */}
      {/* <Link to="/">→ &nbsp;Voice Search</Link> */}
      <Link to="/web-app-developments">→ &nbsp;Web App Development</Link>
      {/* <Link to="/">→ &nbsp;Mobile Application Development</Link> */}
      {/* <Link to="/">→ &nbsp;E-Commerce Applications</Link> */}
    </div>
  );
}
