import React from "react";
import { Helmet } from "react-helmet";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from 'react-router-dom';
import caseImg from "../../../images/casestudy/cs-textile.png";
import img1 from "../../../images/industry-banners/top/9-1.jpg";
import img2 from "../../../images/industry-banners/top/9-2.jpg";
import img3 from "../../../images/industry-banners/top/9-3.jpg";
import Layout from "../../core/Layout";
import CaseStudyPopup from "../services/CaseStudyPopup";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function TextileGarments() {
  window.scrollTo(0, 0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const openCaseStudyPopup = () => {
    setOpenPopup(!openPopup);
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>DigiPrism | Advertise Your Textile &amp; Garments Digitally</title>
        <meta name="description" content="Textile &amp; Garment is an industry that 85% runs on SEO &amp; Influencer Marketing. We have done successful branding for 7 textile brands so far. Shall We Start?" />
      </Helmet>
      <div className="app-banner-indus textile">
        <div className="app-container">
          <div className="app-inside-wrapper">
            <h2>
              DigiPrism <span>Textile &amp; Garments</span>
            </h2>
            <p>
              Textile &amp; Garment is an industry that 85% runs on Word-of-Mouth <br />
              &amp; Influencer Marketing. We have done successful branding for <br />
              7 textile brands so far.</p>
            <p>Shall We Start?</p>
            <Link to="/contact-us" className="app-tell-us">Get A Free Consultation</Link>
          </div>
        </div>
      </div>
      <div className="app-indust-head-all">
        <h3>Your Problems... Sorted!</h3>
      </div>
      <div className="app-left-industry-area textile">
        <div className="app-container">
          <div className="app-flx-wrapp">
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>1.</span>
                Low Customer Retention Rate
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Lack of customer appreciation</li>
                    <li>No incentives for loyalty</li>
                    <li>Cutomer issues not solved</li>
                    <li>No customer-focused website design</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Automated Thank You emails</li>
                    <li>Loyalty program setup</li>
                    <li>Regular social media communications</li>
                    <li>Website design audit and optimization </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>2.</span>
                Brand Awarenes Problems
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>No distinct identity</li>
                    <li>Strong competitors</li>
                    <li>Lack of business reach</li>
                    <li>No trust and authenticity gained</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Attractive logo design and tagline</li>
                    <li>Content audit with competitor analysis</li>
                    <li>Paid social media ads</li>
                    <li>Guest posting and backlinking</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>3.</span>Low Revenues
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>No mobile-friendly website</li>
                    <li>Improper sales pipeline management</li>
                    <li>No sales follow-up</li>
                    <li>High shopping cart abandonment</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Responsive website design</li>
                    <li>Sales cycle shortened, Pipeline review meetings</li>
                    <li>Regular followup emails</li>
                    <li>Abandonment cart remarketing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>4.</span>Low market share
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>No strong online presence</li>
                    <li>Brand name not identifiable</li>
                    <li>No target audience</li>
                    <li>Lack of brand personality</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>GMB setup and directory listing</li>
                    <li>Referral program &amp; social media engagement</li>
                    <li>Proper niche marketing </li>
                    <li>Monthly/weekly social media contest</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-three-channel">
        <div className="app-container">
          <div className="app-top-heading-cov textile">
            <h3>
              Top 3 Marketing Channels for <br />
              <span>Textile &amp; Garments Industry</span>
            </h3>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Voice Search</h4>
                <p>
                  Voice search ensures faster and more accurate results to the
                  search engine user. Voice-optimized SEO can be successful with
                  long-tail keywords and FAQ content. As customers prefer using
                  voice-activated devices, voice search SEO will increase your
                  website ranking. The voice technology promotes customer
                  retention and loyalty.
                </p>
              </div>
              <span className="app-topright"><img src={img1} alt="img" /></span>
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M5 1C73 9 141 78.5 130 183M130 183L141 170.5M130 183L119 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="-0.00012207"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-rgt">
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <span className="app-topleft"><img src={img2} alt="img" /></span>
              <div className="app-topright">
                <h4>Word of Mouth Marketing</h4>
                <p>
                  Word-of-mouth marketing ensures brand discovery among internet
                  users. It increases your brand visibility somewhere beyond ads
                  and search engines. Simple tweeting and sharing drives WOMM.
                  Your customers play a role in triggering this cost-effective
                  and valuable marketing. Reach millions of target audiences
                  within a few seconds.
                </p>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Video Marketing</h4>
                <p>
                  Publish sharable and attractive video content for video
                  marketing. Digital videos are 500% more effective advertising
                  tool. Capture a wide audience and increase conversions with
                  relevant videos. Videos build trust and Google rewards videos
                  with SEO-friendly titles. Mobile-friendly videos also get
                  several views.
                </p>
              </div>
              <span className="app-topright"><img src={img3} alt="img" /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="app-scroll-items-cover wrapp-tree-three">
        <div className="app-container">
          <div className="app-scrl-wrapper">
            <div className="app-spl-latest-left">
              <span>
                <img src={caseImg} alt="case study" />
                <div className="download-btn" onClick={() => openCaseStudyPopup()}>Download</div>
              </span>
              <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                <g filter="url(#filter0_d)">
                  <path
                    d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="6 7.9"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="-9.15527e-05"
                    width="146"
                    height="192"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <p><Link to="/case-study">More Case Study</Link></p>
            </div>
            <div className="app-spl-latest-right">
              <span>
                <svg viewBox="0 0 351.128 351.128">
                  <path d="M72.326,147.33c4.284-26.928,37.944-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.224-0.612,2.448-1.224,3.06-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.976,11.628C38.054,85.518,1.946,136.313,3.782,184.662c-6.12,32.437-4.896,67.32,4.284,96.084c6.12,18.36,23.868,27.54,42.228,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.524-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C115.778,165.078,66.818,181.602,72.326,147.33z" />
                  <path d="M274.286,147.33c4.284-26.928,37.943-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.225-0.612,2.448-1.224,3.061-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.977,11.628c-35.496,29.376-71.604,80.172-69.768,128.52c-6.12,32.437-4.896,67.32,4.283,96.084c6.12,18.36,23.868,27.54,42.229,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.523-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C317.738,165.078,268.166,181.602,274.286,147.33z" />
                </svg>
              </span>
              <h6>
                DigiPrism showed extraordinary passion in building my online
                saree business from scratch and got me thousands of responses
                through social media. Rajib understood my website requirement
                perfectly and delivered the best.
              </h6>
              <p>Co-Founder, EthnicWind</p>
              <div className="app-bg-dot">
                <svg viewBox="0 0 266 246" fill="none">
                  <circle cx="3" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="63" r="3" fill="#F7F7F7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-faq-cover">
        <div className="app-container">
          <div className="app-faq-wrap">
            <div className="app-top-heading-cov textile">
              <h3>
                Frequently Asked <span>Questions</span>
              </h3>
            </div>
            <div className="app-faq-wrapper textile">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I always offer special discounts on garments during
                      festive seasons. How will I let everyone know about my
                      discount offers?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      During holidays, running multiple promotional campaigns
                      online with a specific target and re-target segments gives
                      the best result. We will help you to send promotional
                      emails that contain information related to your latest
                      offers.
                    </p>
                    <p>
                      Moreover, we can use social media to spread the news of
                      special deals from your fashion store. Send us the details
                      of your discount offers, and we will encourage your
                      potential buyers to make their purchases and grab the
                      attractive deals. Very importantly, a responsive landing
                      page with multiple actionable CTAs will help you to
                      increase and track conversion.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have recently created a mobile app for my fashion store.
                      Will I get more traffic on the mobile-friendly platform?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Developing a mobile app is surely an important step to
                      promote your products. However, you need a mobile-specific
                      digital marketing strategy to reach your target customers.
                      We know the mobile app marketing techniques the best and
                      are ready to help you to drive more sales in the coming
                      months.
                    </p>
                    <p>
                      Moreover, our professionals can check out that the
                      Mobile-friendly content on the app is optimized properly.
                      You can choose our online marketing services to persuade
                      your potential buyers to install the app.
                    </p>
                    <p>
                      Location-based marketing, On-site and in-app support,
                      Mobile-friendly content, Responsive website design, Voice
                      search optimization, Social media channels, and Text
                      message marketing are a few of our masterstrokes.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have a special garment collection for women. How will
                      showcase the products to lots of target customers?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Digital marketing has made it easy to make your product
                      catalog viewable to everyone. And in this case, Product
                      Display Advertising can help you the most with Target Ad
                      Delivery &amp; groups, integration of Application Program
                      Interface (API), Contextual Targeting, and Display
                      Campaign Optimize.
                    </p>
                    <p>
                      Another way, let’s say; when you have a special summer
                      collection for women, you can create a video of those
                      products. Our marketers will help you in promoting the
                      video on different platforms. As the potential buyers will
                      share those videos with their friends and other contacts,
                      you can find the chance of increasing sales.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I have a local brick-and-mortar garment store. Should I
                      create an online platform for my business?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      87% of Indian Millennial and Generation Z buyers love
                      online shopping, and thus, you can miss out on a good
                      chunk of customers. Building up an online presence starts
                      with a responsive website and social media presence. Let
                      us take the plunge for you.
                    </p>
                    <p>
                      You can grab a good share in the local market with online
                      advertising of your business. By creating a profile on the
                      GMB page, we will make your prospects aware of your store
                      name. 'Virtual Tour Google My Business' is a new addition
                      during this lockdown where your business is up to 94% more
                      likely to be viewed by your potential customers. Moreover,
                      customer reviews on the GMB page will help you in your
                      organic SEO listing.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I want to provide a style guide to my target customers.
                      How will you help me in this respect?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The best option for you is to write blogs on it. You can
                      choose our bloggers to write SEO-friendly content on
                      fashion and garments. By writing useful and valuable
                      content, we will engage the attention of your website
                      visitors. Every season, you can help your customers with a
                      perfect online catalog guide. Based on that guide, your
                      store visitors can make the best choice of outfits and
                      place their orders.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I like to spread my fashion brand name to potential
                      customers all over the country. How will I do it with
                      online marketing?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      You can run a Facebook or Instagram giveaway contest, and
                      you will surely find lots of contestants in this program.
                      With several shares and comments, you can make your brand
                      name familiar to everyone. We are ready to help you in
                      running the campaign on different platforms. We will also
                      add special hashtags to make your content reach to lots of
                      social media users. Within a short time, you can
                      accomplish your goals of promoting your fashion brand.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Show me a detailed Textile and Garments success story.
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="divCls">
                      <b>About the brand</b>
                      It is an Indian fashion brand with both online and offline
                      presence. Known for designing and selling garments, this
                      company started its journey in the year 2004. During its
                      origin, it had a small boutique and manufacturing unit for
                      only local customers. However, despite having passion and
                      dedication, the brand owner faced barriers to growing his
                      business due to a lack of marketing efforts. We, as a team
                      of digital marketers, collaborated with them and
                      successfully uplifted their position in the fashion
                      industry.
                    </p>
                    <div className="divCls">
                      <b>Condition before we worked with the garment brand</b>
                      <ul>
                        <li>
                          SEO elements of its website are not properly
                          configured.
                        </li>
                        <li>
                          The brand failed to draw the attention of the young
                          generation.
                        </li>
                        <li>
                          Although it was getting customers in its local
                          outlets, it could not receive visitors in its online
                          platform.
                        </li>
                        <li>
                          As a startup, the brand found hindrances in the path
                          of growth due to the strong competitors.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Problem Statement</b>
                      <ul>
                        <li>
                          No valuable traffic resulting in the low conversion
                          rate in the website{" "}
                        </li>
                        <li>Very few followers in the social media pages</li>
                        <li>
                          No response to promotional emails sent on a weekly
                          basis
                        </li>
                        <li>The garment business was slowly lagging behind</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Our objectives/KPIs</b>
                      <ul>
                        <li>To have a hike in the organic traffic</li>
                        <li>To lower the bounce rate by more than 60%</li>
                        <li>To get lots of positive feedbacks from buyers</li>
                        <li>To increase the number of loyal customers</li>
                        <li>To raise the sales rate and revenues</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>What we have done to achieve these KPIs</b>
                      <b>
                        Search Engine Optimization and Search Engine Marketing
                      </b>
                      <p>
                        Very low traffic, high shopping cart abandonment rate,
                        slow page loading, and low SERP ranking- these were
                        major obstacles to the brand. Without using unethical
                        hacks and tricks, we have elevated their website rank
                        and settled other problems.
                      </p>
                      <p>A brief outline of how we had done it</p>
                      <ul>
                        <li>
                          From our initial analysis, we have solved the major
                          issues, like large files, IP number complications,
                          absence of Robots.txt File, and missing sitemaps.
                        </li>
                        <li>
                          With over 50-hour research, we worked on keyword
                          analysis and proper keyword combinations.
                        </li>
                        <li>
                          By applying state-of-the-art SEO techniques, we made
                          webpages informative and rich in content.
                        </li>
                        <li>
                          Made target market analysis and assessed competitors’
                          websites
                        </li>
                        <li>
                          We also shared the garment website link with blogs,
                          podcasts, and social media.
                        </li>
                        <li>
                          Our content developers also created the most concise
                          and compelling copy.
                        </li>
                      </ul>
                      <p>Besides, other efforts of our team include</p>
                      <ul>
                        <li>Found passive link acquisition channels</li>
                        <li>
                          Started a remarketing campaign with Google display
                          network
                        </li>
                        <li>Inserted inbound and outbound links</li>
                        <li>Added conversion forms for lead generations</li>
                        <li>
                          Optimized content with LSI keywords and semantic
                          keywords
                        </li>
                        <li>Added rich snippets</li>
                        <li>Published guest bloggs</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Social Media Marketing campaign</b>
                      <p>
                        The garment brand did not find a way of connecting with
                        the target audience. We pinched SMM ideas to achieve
                        more than 14,000 likes for social media posts, over
                        70,000,000 organic impressions, and thousands of shares
                        on Facebook and Instagram. This is how we reached our
                        goals in our 4-month campaign.
                      </p>
                      <ul>
                        <li>
                          We regularly published social media posts on trending
                          fashion topics
                        </li>
                        <li>
                          Moreover, our team retargeted readers from the
                          referral blog site.{" "}
                        </li>
                        <li>
                          With viral marketing, our relevant videos gained more
                          than 2M+ views
                        </li>
                        <li>
                          We also hosted social media contests for Facebook and
                          Instagram users
                        </li>
                        <li>
                          Our advertisers took care of the paid social ads to
                          make them attractive
                        </li>
                        <li>
                          We reached our social message to the target audience
                        </li>
                        <li>
                          Besides, our team constantly maintained Online Public
                          Relations.to gain trust.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Influencer marketing</b>
                      <p>
                        When the garment company contacted us for the first
                        time, low time and low engagement were the biggest
                        issues. We spotted that due to the lack of credibility
                        and brand visibility, it had these problems.
                      </p>
                      <p>
                        We have now briefly summarized the way we solved it.
                      </p>
                      <ul>
                        <li>
                          First, our team finds influencers who can influence
                          the target demographics of the brand.
                        </li>
                        <li>
                          We narrated the brand story to influencers to let them
                          share it with audiences.
                        </li>
                        <li>
                          While collaborating with Instagram influencers and
                          YouTubers, we made a visual storytelling approach.
                        </li>
                      </ul>
                      <p>What we have achieved</p>
                      <ul>
                        <li>Millions of social media views</li>
                        <li>Tons of user-generated content</li>
                        <li>More brand mentions</li>
                        <li>Higher Return on Relationships </li>
                        <li>Over 300% increase in website traffic</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Email marketing and lead generation</b>
                      <p>
                        At the time of launching the garment brand, it was not
                        easy for the company to attract leads. The customer
                        retention rate was also low. Our marketers started a
                        lead generation and email campaign to manage the
                        problem.
                      </p>
                      <ul>
                        <li>
                          We identified the best channels to source leads.
                        </li>
                        <li>
                          To increase leads, we removed broken links from the
                          site, removed duplicate content across subdomains, and
                          deleted thin blog posts.
                        </li>
                        <li>
                          As the brand designs garments for men, women, and
                          kids, we focused on email segmentation.
                        </li>
                        <li>
                          Our writer and designers crafted attractive email
                          newsletters with CTA buttons.
                        </li>
                        <li>
                          Based on our created schedule, we automated the email
                          marketing campaign.
                        </li>
                        <li>
                          Besides, our cold emails, auto-follow-ups, and drip
                          marketing.
                        </li>
                      </ul>
                      <p>What we have achieved</p>
                      <ul>
                        <li>A decrease in grey mails</li>
                        <li>Reduction in bounce rate</li>
                        <li>Increase in conversion and email-open rates</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Outcomes</b>
                      <p>
                        With our determination and dedication for 7 months, we
                        have been in continuous focus on the success metrics.
                        Finally, we have attained a result that goes far beyond
                        our client’s anticipations. We just like to give an
                        amazing numeric presentation of our achievements
                      </p>
                      <ul>
                        <li>More than 500% increase in sales</li>
                        <li>An increase in monthly traffic by 40%</li>
                        <li>
                          More than 2,360 visitors in the first few months of
                          the campaign
                        </li>
                        <li>Over 14,000 new email subscribers</li>
                        <li>170% increase in contact form submission</li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {openPopup && <CaseStudyPopup openCaseStudyPopup={openCaseStudyPopup} />}
    </Layout>
  );
}
