import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import Recaptcha from 'react-recaptcha';
import contactCancel from '../../../images/cancel.svg';
import contactSuccess from '../../../images/success_contact.svg';
import { basepath } from '../../core/config';

export default function HomePopup({ openHomePopup }) {
  let counCode, counName, fromWhere = "Home Page";
  const validNumberRegex = /^[0-9\s]*$/;
  const validEmailRegEx = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [isVerify, setIsVarify] = useState(false);
  const [captMsg, setCaptMsg] = useState("");
  const [initialValue, setInitialValue] = useState({ 
    website: '', 
    business: '',
    name: '',
    mobile: '',
    email: ''
  });
  const [errorValidate, setErrorValidate] = useState({ 
    websiteError: '', 
    businessError: '',
    nameError: '',
    mobileError: '',
    emailError: ''
  });
  const [disError, setDisError] = useState({ 
    websiteDisError: false, 
    businessDisError: false,
    nameDisError: false,
    mobileDisError: false,
    emailDisError: false
  });
  const handleChange = event => {
    event.preventDefault();
    setErrorValidate({ 
      websiteError:'', 
      businessError: '',
      nameError: '', 
      mobileError: '', 
      emailError: ''
    });
    setInitialValue({ 
      ...initialValue, 
      [event.target.name]: event.target.value 
    });
  };
  const onSelectPhone = (phone) => {
    return;
  };
  const submitContact = () => {
    if (initialValue.website === "") {
      setErrorValidate({ websiteError: "Please enter website" });
      setDisError({ websiteDisError: true });
      return;
    }
    if (initialValue.business === "") {
      setErrorValidate({ businessError: "Please enter your business problems" });
      setDisError({ businessDisError: true });
      return;
    }
    if (initialValue.name === "") {
      setErrorValidate({ nameError: "Please enter name" });
      setDisError({ nameDisError: true });
      return;
    }
    if (initialValue.mobile === "") {
      setErrorValidate({ mobileError: "Please enter contact" });
      setDisError({ mobileDisError: true });
      return;
    }
    if (!validNumberRegex.test(initialValue.mobile)) {
      setErrorValidate({ mobileError: "String or special character is not allow" });
      setDisError({ mobileDisError: true });
      return;
    }
    if (initialValue.mobile.length !== 10 || initialValue.mobile.length > 10) {
      setErrorValidate({ mobileError: "Phone number must be of atleast 10 characters", });
      setDisError({ mobileDisError: true });
      return;
    }
    if (initialValue.email === "") {
      setErrorValidate({ emailError: "Please enter email" });
      setDisError({ emailDisError: true });
      return;
    }
    if (initialValue.email !== "" && !validEmailRegEx.test(initialValue.email)) {
      setErrorValidate({ emailError: "Please enter a valid email address" });
      setDisError({ emailDisError: true });
      return;
    }
    if(isVerify === false) {
      setCaptMsg("Please verify captcha");
      return;
    }
    let payload = {
      website: initialValue.website,
      business: initialValue.business,
      name: initialValue.name,
      mobile: `+${counCode} ${initialValue.mobile}`,
      email: initialValue.email,
      country: counName,
      fromWhere: fromWhere,
    };
    const configdata = {
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json", },
      body: JSON.stringify(payload),
    };
    fetch(`${basepath}/basicinfo`, configdata)
      .then((res) => res.json())
      .then((res) => {
        if(res.status === "OK") {
          setShowSuccess(true);
          setShowForm(false);
          setShowError(false);
        }
      })
      .catch((err) => {
        setShowSuccess(false);
        setShowForm(false);
        setShowError(true);
      });
  }

  const verifyLoad = () => {
    setIsVarify(false);
  }

  const verifyCallback = (res) => {
    if(res) setIsVarify(true);
    setCaptMsg("");
  };

  return (
    <div className="popup">
      <div className="popup-inner home">
        <span className="close-popup" onClick={() => openHomePopup()}>
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 511.995 511.995">
            <path d="M437.126,74.939c-99.826-99.826-262.307-99.826-362.133,0C26.637,123.314,0,187.617,0,256.005s26.637,132.691,74.993,181.047c49.923,49.923,115.495,74.874,181.066,74.874s131.144-24.951,181.066-74.874C536.951,337.226,536.951,174.784,437.126,74.939z M409.08,409.006c-84.375,84.375-221.667,84.375-306.042,0c-40.858-40.858-63.37-95.204-63.37-153.001s22.512-112.143,63.37-153.021c84.375-84.375,221.667-84.355,306.042,0C493.435,187.359,493.435,324.651,409.08,409.006z" />
            <path d="M341.525,310.827l-56.151-56.071l56.151-56.071c7.735-7.735,7.735-20.29,0.02-28.046c-7.755-7.775-20.31-7.755-28.065-0.02l-56.19,56.111l-56.19-56.111c-7.755-7.735-20.31-7.755-28.065,0.02c-7.735,7.755-7.735,20.31,0.02,28.046l56.151,56.071l-56.151,56.071c-7.755,7.735-7.755,20.29-0.02,28.046c3.868,3.887,8.965,5.811,14.043,5.811s10.155-1.944,14.023-5.792l56.19-56.111l56.19,56.111c3.868,3.868,8.945,5.792,14.023,5.792c5.078,0,10.175-1.944,14.043-5.811C349.28,331.117,349.28,318.562,341.525,310.827z" />
          </svg>
        </span>
        <div className="body-popup">
          <div className="pop-up">
            <div className="blg-right-pop">
              {showSuccess || showError ? "" : (<div className="popup-blog-heading">YOUR FREE DIGITAL MARKETING PROPOSAL</div>)}
              {showForm && (
                <div className="popup-body-content-case-blog">
                  <p>Please Tell Us Who &amp; Where To Sent It.</p>
                  <div className="pop-input-casestudy">
                    <div className="app-fld">
                      <input 
                        type="text" 
                        placeholder="Your Website *" 
                        name="website" 
                        value={initialValue.website} 
                        onChange={(event) => handleChange(event)} 
                      />
                      {disError.websiteDisError && (<p className="error-validation">{errorValidate.websiteError}</p>)}
                    </div>
                    <div className="app-fld">
                      <input 
                        type="text" 
                        placeholder="Main Business Problem" 
                        name="business" 
                        value={initialValue.business} 
                        onChange={(event) => handleChange(event)} 
                      />
                      {disError.businessDisError && (<p className="error-validation">{errorValidate.businessError}</p>)}
                    </div>
                    <div className="app-fld">
                      <input 
                        type="text" 
                        placeholder="Name *" 
                        name="name" 
                        value={initialValue.name} 
                        onChange={(event) => handleChange(event)} 
                      />
                      {disError.nameDisError && (<p className="error-validation">{errorValidate.nameError}</p>)}
                    </div>
                    <div className="app-fld contact">
                      <PhoneInput
                        onChange={phone => onSelectPhone(phone)}
                        isValid={(phone, country) => {
                          counCode = phone;
                          counName = country.name;
                        }}/>
                      <input 
                        type="text" 
                        placeholder="Mobile no *" 
                        name="mobile" 
                        value={initialValue.mobile} 
                        onChange={(event) => handleChange(event)} 
                      />
                      {disError.mobileDisError && (<p className="error-validation">{errorValidate.mobileError}</p>)}
                    </div>
                    <div className="app-fld">
                      <input 
                        type="email"
                        placeholder="Email *" 
                        name="email" 
                        value={initialValue.email} 
                        onChange={(event) => handleChange(event)} 
                      />
                      {disError.emailDisError && (<p className="error-validation">{errorValidate.emailError}</p>)}
                    </div>
                    <div className="app-fld">
                      <Recaptcha
                        sitekey="6Le8wGMUAAAAACI5S8XWfjIAvVqkQ1vaS7u8agqw"
                        render="explicit"
                        onloadCallback={() => verifyLoad()}
                        verifyCallback={verifyCallback}
                      />
                      {isVerify ? "" : (<p className="error-validation">{captMsg}</p>)}
                    </div>
                    <span className="dwn" onClick={() => submitContact()}>Call Me Back !</span>
                    <span className="cncel" onClick={() => openHomePopup()}>Cancel</span>
                  </div>
                </div>
              )}
              {showSuccess && (
                <div className="app-success-state">
                  <span><img src={contactSuccess} alt="Contact Success" /></span>
                  <h3>Thank you for contacting us!</h3>
                  <p>We have received your message. We'll reach you out immediately!</p>
                </div>
              )}
              {showError && (
                <div className="app-error-state">
                  <span><img src={contactCancel} alt="Contact Success" /></span>
                  <h3>Oops! Sorry..</h3>
                  <p>We're sorry. Something went wrong. Please try again.</p>
                </div>
              )}
            </div>
            <div className="pop-left-bg homebg"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
