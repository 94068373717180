import React from 'react';
import blog3 from "../../../images/blog/blog3.jpg";
import Layout from "../../core/Layout";
import GetQuotePopup from "../services/GetQuotePopup";

export default function Blog3() {
  window.scrollTo(0, 0);
  const [openQuote, setOpenQuote] = React.useState(false);
  const getQuotePopOpen = () => {
    if(localStorage.getItem("quoteraised")) {
      return setOpenQuote(false);
    }
    setOpenQuote(!openQuote);
  }

  return (
    <Layout>
      <div className="app-blog-wrapper">
        <div className="app-container">
          <div className="app-wrap-blog">
            <span>
              <img src={blog3} alt="Everything you need to know about surrogate advertising" />
              <b onClick={() => getQuotePopOpen()}>Download PDF</b>
            </span>
            <h2>Everything you need to know about surrogate advertising</h2>
            <div className="app-static-page-wrap">
              <p>The concept of advertising is nothing new in the present age. All entrepreneurs know that without the most effective advertising campaign, they cannot market their products. In fact, as the corporate world has become highly competitive, an advertising campaign is a must for businesses. With advertisements, businesses need to reach their product names and information to the target customers. However, based on the types of services and products, businesses need to choose the marketing technique. Now, we are going to present you with details of surrogate advertising.</p>

              <h5>Surrogate marketing - A concept of the advertisement</h5>
              <p>You know that tobacco, liquor, drugs, and narcotics are some banned products. Thus, the normal advertising technique may not work for them. In simple words, surrogate marketing refers to a type of advertising, which is effective for promoting banned products, like alcohol and cigarettes, in the disguise of some other products. As the brands manufacturing these products have no other way of running a marketing campaign, their last resort is the surrogate advertising. These businesses never promote the actual product. However, they want to push their brand names into the mind of their potential customers.</p>
              <p>In a surrogate marketing campaign, brands intentionally use an object, a company, or a person to communicate the real message of the target group.</p>

              <h5>Surrogate advertising- From Britain to India</h5>
              <p>You could notice the origin of surrogate marketing several years ago. It had a range of connotations. In addition to liquor and narcotic products, it is applicable to services like pornography. However, a few years ago, it came into our limelight. We have found the real beginning of this surrogate advertising in Britain. Housewives in this country started a protest against liquor ads that provoke their spouses to drink alcohol.</p>
              <p>Then, in due course, we also noticed the application of this marketing technique. There are some strict rules and regulations related to the banned goods, including alcohol and tobacco. That is why most of the concerned brands have found a creative way to market their products to the public. Some surveys in India have revealed that liquor ads directly influence the purchasing behavior of consumers. Then, the cable TV regulation Act put a ban on cigarette and liquor ads. That is why Indian brands started implementing the surrogate advertising techniques. </p>

              <h5>Origin of surrogate marketing</h5>
              <p>We all know that advertising is the most potent tool for marketers and brand owners. While launching a new product, educating customers on new features, and promoting other products, advertising is the best solution. Direct marketing of alcohol, tobacco, and similar other products was much rampant before enforcing tobacco control laws in India. However, billboard ads of domestic and international cigarettes brands and chewable tobacco were common. But, at present, direct promotion of tobacco and other banned products in different media is legally prohibited. Cable Television Network Amendment Bill,2000 bans alcohol and cigarette advertisements. This ban has resulted in the new trend of surrogate marketing. Most of the brands have learned the way of selling their products without interacting with consumers.</p>

              <h5>Scenario before the legal enforcement</h5>
              <p>The alcohol and tobacco industry used to sponsor cultural events and sports events-</p>
              <ul className="w-full">
                <li>Wills (an ITC brand) sponsored the Indian cricket team</li>
                <li>Manikchand, a gutkha manufacturer, endorsed the Filmfare awards ceremony.</li>
                <li>Charms, a reputed cigarette brand, promoted the musical event, Spirit of freedom concert.</li>
                <li>Four Square, a cigarette brand, sponsored a boat race.</li>
              </ul>

              <h5>Scenario after the legal enforcement</h5>
              <p>Manufacturers of tobacco, alcohol, and cigarette felt the real predicament after the enforcement of strict laws. Although they can legally manufacture their products, they cannot market them. Lots of cigarette and liquor companies have found issues due to this law.</p>

              <h5>Legal rules regarding surrogate advertising</h5>
              <ul className="w-full">
                <li>The storyboard of the ad must not depict the prohibited product in any way.</li>
                <li>The ad should not make direct and indirect reference to the banned product.</li>
                <li>It must not have nuances and phrases that promote the illegal item.</li>
                <li>The advertisement should never use layout and colors that relate to the prohibited product.</li>
                <li>It must not reveal situations relevant to the marketing of prohibited items while promoting other products.</li>
              </ul>
              <p>Thus, to avoid legal complications, brands have to know these rules and regulations about surrogate marketing.</p>
              
              <h5>Surrogate advertising- Pros and cons</h5>
              <p>You can notice both pros and cons of this surrogate advertising. A surrogate marketing campaign is something related to viral marketing. The marketing agency needs to employ others to convey the desired message. As a seller, you may also hire a manufacturer’s representative to simplify the sale of the product. However, the negative side of the advertising is that it is intended to market unhealthy, unethical, and illegal products. Brands promote them with some activities, which are acceptable.</p>
              <p>Surrogate advertising has some other social, economic, and legal effects. The government earns revenues and media can gain profit due to the high paying ads. However, companies need to invest a high amount in these ads. </p>
              <p>Some of us think that surrogate ads mislead younger generation. Nevertheless, they do not affect consumers who do not know anything about the product. They focus on the selective and target segment in the society. Without breaking legal rules, companies can promote their banned products.</p>
              <p>As the government has banned surrogate ads, lots of companies have turned to event organizing, event sponsorship, corporate films, and some other integrated marketing campaigns.</p>

              <h5>Brands displaying surrogate ads</h5>
              <p>Some tobaccos and .liquor brands engaged in surrogate ads</p>
              <ul className="w-full">
                <li>Kingfisher - The product is beer and the communicating products are calendars and mineral water.</li>
                <li>Bagpiper Soda - The product is whisky and the communicating products are soda, water, cassettes, and CDs</li>
                <li>McDowells - The product is whisky and the communicating product is soda and water.</li>
                <li>Wills Lifestyle - The major product is cigarette and the communicating products are cloths and accessories.</li>
                <li>Pan Parag - The major product is gutkha and the communicating product is pan masala.</li>
                <li>Royal Challenge - The product of the company is whisky and the communicating products are mineral water and golf accessories.</li>
              </ul>
              <p>Every brand has adopted a variety of tactics for surrogate advertising. For instance, instead of marketing whisky, Royal Stag displays Mega Cricket and Music CD, and the theme of the ad is- Make it Large. Similarly, another recognized brand, Hayward’s 5000, has made an advertisement with packaged drinking water and soda. The storyline of Imperial Blue’s advertisement is Men will be Men. This ad represents the brand name as a symbol of masculinity. The major goal of these ads is to hide the product while introducing the brand name to the potential customers.</p>

              <h5>Surrogate advertising- Advantages and disadvantages</h5>
              <h6>Advantages</h6>
              <ul className="w-full">
                <li>It enables brands to increase the number of sales of banned items</li>
                <li>It is an indirect way to remind customers about these illegal products.</li>
                <li>As no brand can make direct advertisements, there is a minimal threat from new competitors.</li>
              </ul>
              <h6>Disadvantages</h6>
              <ul className="w-full">
                <li>It gives no value to the legal bans imposed by governments</li>
                <li>It affects the society, as kids cannot differentiate the advertised products from the banned one.</li>
              </ul>

              <h5>Surrogate marketing- Does this advertising technique work?</h5>
              <p>Of course, this advertising technique is effective for brands. 50% of viewers who watch the ads, can identify the target product with the surrogate product. They can detect the brand name and relate to the right product.</p>
              <p>Sales of illegal and banned products can reveal a direct relationship with surrogate ads. Thus, they reinstate the effect on the consumer’s buying decision.</p>

              <h5>Substitutes of alcohol and liquor- A threat</h5>
              <p>Due to the advancement in our standard of living, we have become highly conscious of our health. Most of the consumers know that liquor may be detrimental to health. That is why lots of consumers have made a shift from liquor to healthy juices, soft drinks, and other non-alcoholic drinks.</p>
              
              <h5>Surrogate ads of different types</h5>
              <ul className="w-full">
                <li>TV ads displaying a storyline in a way that the company does not directly reveals its product</li>
                <li>Sporting teams and sponsoring events- Another way of promoting the brand dealing with banned products</li>
                <li>Public service announcements- The most commonly chosen way of advertising products</li>
              </ul>

              <h5>Surrogate advertising and the use of digital platforms</h5>
              <p>Traditionally, surrogate marketing is highly reliant on radio, TV, and newspapers. However, nowadays, brands have started choosing the online platform. They think that as there are no clear guidelines, they can take the advantage. Lots of Indian brands have turned to social networking platforms for promoting products and services. They attempt to evade the law with the branded content. </p>
              <p>Kingfisher, the beer brand, has claimed they have woven the product into some interesting story. This initiative has enabled them to keep away from the obvious and clear advertisement. Their digital ads have become viral to the online users.</p>
              <p>However, AdWords, one of the most known PPC advertising platform, has imposed strict rules against marketing alcohol. In the UK, USA, and Australia, brands can advertise alcohol, although some restrictions exist. But, in India, there is a full ban on ads targeting alcohol.</p>
              <p>Social networking sites, including Twitter and Facebook, have some gray area for brands producing alcohol and beer. Some beer brands have developed a community on those platforms instead of directly promoting the product.</p>
              <p>Kingfisher, the famous beer maker, is highly active on social platforms. It regularly communicates with the urban citizens, young consumers, and other alcohol enthusiasts. With funny and creative tweets and contests, it engages audiences. </p>

              <h5>Surrogate marketing- Position in the film industry</h5>
              <p>In the present scenario, low-budget movies play a role in this advertising program. While watching films, you notice some surrogate adverts during some ongoing scenes. Actors directly endorse brands, like McDowell, Royal Stag, and Bacardi, to promote tobacco and alcohol brands with surrogate advertising. Thus, movies have turned out to be the most effective medium to continue surrogate marketing. Dindigul Sarathi, Dhanam, and other Kollywood movies, have included this surrogate advertising.</p>

              <h5>Surrogate marketing - Is it ethical?</h5>
              <p>Surrogate advertising is useful, while corporations have to cultivate the trends of social responsibilities. This marketing technique has turned out to be a debatable topic because direct marketing of tobacco and liquor is prohibited in India. However, while the company displays the ad in the way of increasing brand image, the legal banning process becomes defeated.</p>
              <p>However, some of us claim that these surrogate ads are misleading and have a negative impact on the overall market. The most important thing is the perception of viewers about these ads. Everything depends on how the viewer analyzes the positive and negative sides of the ads.</p>

            </div>
          </div>
        </div>
      </div>
      {openQuote && <GetQuotePopup getQuotePopOpen={getQuotePopOpen} />}
    </Layout>
  )
}
