import React from "react";
import { Helmet } from "react-helmet";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from 'react-router-dom';
import caseImg from "../../../images/casestudy/cs-hospitality.png";
import img1 from "../../../images/industry-banners/top/3-1.jpg";
import img2 from "../../../images/industry-banners/top/3-2.jpg";
import img3 from "../../../images/industry-banners/top/3-3.jpg";
import Layout from "../../core/Layout";
import CaseStudyPopup from "../services/CaseStudyPopup";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function Hospitality() {
  window.scrollTo(0, 0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const openCaseStudyPopup = () => {
    setOpenPopup(!openPopup);
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>Digital Services for Hospital Industry | DigiPrism</title>
        <meta name="description" content="Collapsed completely due to COVID? Bounce Back with Personalized Email Marketing, Progressive Web Apps, and Geofencing Growth. Book A Free Promotion Now!" />
      </Helmet>
      <div className="app-banner-indus hospitality">
        <div className="app-container">
          <div className="app-inside-wrapper">
            <h2>
              DigiPrism <span>Hospitality</span>
            </h2>
            <p>
              Collapsed completely due to COVID? Hard to keep the <br />business
              alive? We have taken out hotels and <br />restaurants from here.</p>
            <p>Give us a call to discuss things in detail.</p>
            <Link to="/contact-us" className="app-tell-us">Get A Free Consultation</Link>
          </div>
        </div>
      </div>
      <div className="app-indust-head-all">
        <h3>Your Problems... Sorted!</h3>
      </div>
      <div className="app-left-industry-area hospitality">
        <div className="app-container">
          <div className="app-flx-wrapp">
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>1.</span>
                Declining rate of monthly deals
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>No interactive content </li>
                    <li>Problem in business outreach</li>
                    <li>Low website traffic</li>
                    <li>No customer-focused marketing</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>YouTube marketing</li>
                    <li>Scheduled drip marketing</li>
                    <li>Long-tail keyword placement</li>
                    <li>Forum marketing and networking</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>2.</span>
                Low engagement rate
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Lack of interest</li>
                    <li>Very few potential visitors</li>
                    <li>No strong identity </li>
                    <li>Lack of authenticity </li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Micro and mega influencer marketing</li>
                    <li>Domain marketing</li>
                    <li>Visual identify development</li>
                    <li>Brand personality development</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>3.</span>Failure to capture attention
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>No proper advertisements</li>
                    <li>On-site SEO issues</li>
                    <li>Lack of data</li>
                    <li>No new followers</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Google ads posting</li>
                    <li>Proper CTA placement</li>
                    <li>Social listening and marketing</li>
                    <li>Content reposting</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>4.</span>Slow business growth
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Lack ofcustomers’ interactions</li>
                    <li>No proper content management</li>
                    <li>High CPM</li>
                    <li>Lack of response</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>CRM system installation</li>
                    <li>Newsletter and blog content curation</li>
                    <li>Ad format refinement</li>
                    <li>Direct marketing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-three-channel">
        <div className="app-container">
          <div className="app-top-heading-cov hospitality">
            <h3>
              Top 3 Marketing Channels for <br />
              <span>Hospitality Industry</span>
            </h3>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Personalized Email Marketing</h4>
                <p>
                  The word ‘Hospitality’ eats and breaths on ‘personalization’.
                  In hospitality industry, re-marketing emails work by far the
                  best than all the other channels. Email marketing helps you to
                  gain deep analytics for other channels as well. Email
                  marketing for most of the hospitality giants work as an
                  ethical data hub.
                </p>
              </div>
              <span className="app-topright"><img src={img1} alt="img" /></span>
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M5 1C73 9 141 78.5 130 183M130 183L141 170.5M130 183L119 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="-0.00012207"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-rgt">
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <span className="app-topleft"><img src={img2} alt="img" /></span>
              <div className="app-topright">
                <h4>Progressive Web Apps</h4>
                <p>
                  The most important aspect of hospitality industry is
                  customer’s comfort. Progressive web app uses latest
                  technologies to bring to the customers the usability of a
                  responsive website and the comfort of mobile apps. PWA is an
                  integral part of modern marketing thanks to it’s easy
                  maintenance and easy-deploy nature.
                </p>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Geofencing Growth</h4>
                <p>
                  Geofencing is the most dynamic mobile-marketing optimization
                  strategy that allows you to target the most potential
                  travelling and residence audience who may need your
                  hospitality the most. Geofencing Marketing helps in better
                  targeting, personalizes the entire customer journey, increases
                  local sales and increase overall customer engagement.
                </p>
              </div>
              <span className="app-topright"><img src={img3} alt="img" /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="app-scroll-items-cover wrapp-tree-three">
        <div className="app-container">
          <div className="app-scrl-wrapper">
            <div className="app-spl-latest-left">
              <span>
                <img src={caseImg} alt="case study" />
                <div className="download-btn" onClick={() => openCaseStudyPopup()}>Download</div>
              </span>
              <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                <g filter="url(#filter0_d)">
                  <path
                    d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="6 7.9"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="-9.15527e-05"
                    width="146"
                    height="192"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <p><Link to="/case-study">More Case Study</Link></p>
            </div>
            <div className="app-spl-latest-right">
              <span>
                <svg viewBox="0 0 351.128 351.128">
                  <path d="M72.326,147.33c4.284-26.928,37.944-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.224-0.612,2.448-1.224,3.06-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.976,11.628C38.054,85.518,1.946,136.313,3.782,184.662c-6.12,32.437-4.896,67.32,4.284,96.084c6.12,18.36,23.868,27.54,42.228,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.524-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C115.778,165.078,66.818,181.602,72.326,147.33z" />
                  <path d="M274.286,147.33c4.284-26.928,37.943-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.225-0.612,2.448-1.224,3.061-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.977,11.628c-35.496,29.376-71.604,80.172-69.768,128.52c-6.12,32.437-4.896,67.32,4.283,96.084c6.12,18.36,23.868,27.54,42.229,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.523-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C317.738,165.078,268.166,181.602,274.286,147.33z" />
                </svg>
              </span>
              <h6>
                Services taken: ASP.NET website, complete SEO, blog, email
                service. Very much satisfied. Will refer.
              </h6>
              <b>Richard Croft</b>
              <p>Pearl Studio</p>
              <div className="app-bg-dot">
                <svg viewBox="0 0 266 246" fill="none">
                  <circle cx="3" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="63" r="3" fill="#F7F7F7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-faq-cover">
        <div className="app-container">
          <div className="app-faq-wrap">
            <div className="app-top-heading-cov hospitality">
              <h3>
                Frequently Asked <span>Questions</span>
              </h3>
            </div>
            <div className="app-faq-wrapper hospitality">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I have a chain of hotels to serve travelers. How will I increase the number of bookings?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    With the right promotional tricks, we can increase your revenues with more bookings. As you have built a website, we help you with On-Page and Off-Page SEO to ensure a high level of organic traffic. Moreover, our data collection and automation technologies help us to do personalized marketing. With targeting and re-targeting, we reduce the abandonment rate for reserving hotels. Besides, our Link Building tactics will enable you to make local partnerships for increasing ROI.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I have established a new restaurant. How does a digital marketing campaign help me to promote it?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    As you are a startup, we need different marketing channels for promotions. With Press Release writing, Video Marketing, and Social Media Page management, we will promote your restaurant. Moreover, our PPC ads will help you get valuable traffic to the official website of your restaurant. Other marketing solutions valuable for your new restaurant are Location-based marketing, Text message marketing, Lead Generation Campaign, and Voice search optimization.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I am going to offer discounts on hotel room reservations. How will I spread this news to my potential customers?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    We will capture your leads with special tracking tools to know their email IDs. Then, with Email Marketing, Newsletter Advertising, and Direct Marketing, we will inform them about the latest discount offers. Moreover, we will create Video Commercials and publish them on your social media pages. Our graph designers will also design popups and add them to your website. Besides, our remarketing ads for attractive deals will work best for your hotel.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    My hotel website ranking is getting low. Can you help me to have better status?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    Our SEO audit process will enable us to address your website issues. With technical SEO, we make out the broken links, webpage loading errors, link qualities, link penalties, and bad links. Our content development professionals will check out whether your website has thin content.  We also use Structured Data Testing tools to analyze every page. Finally, we will send a report of our web content audit, SEO link audit, follow-up site audit, and UI design audit.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I have recently created a social media page for my food and drinks brand. How will I engage lots of followers to it?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    We have Social Media Ads manager in our SMM team. With split testing of your social media posts, we will make your brand name visible to lots of potential customers. Our team will also publish videos and share blogs with your followers and encourage them to invite others to Like the page.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I am a personal coach, and I like to draw attention of potential clients. Can I do it within a short time?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    We think that Viral Marketing is the right choice for you. We produce some unpredicted content that nurtures the emotion of viewers. By sharing interactive and amazing videos and images, we grab lots of views of social media users. Moreover, we embed downloading and sharing option for those videos. While your videos go viral, you can increase your social outreach. 
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Show me a detailed Hospitality success story.
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="divCls">
                      <b>About the company</b>
                      It is a chain of hotels with businesses in different parts
                      of India. Although it had established a big chain of
                      hotels, it started its journey as simply one small
                      boutique hotel. During the first few years of its
                      establishment, it had a few customers. It used to receive
                      guests only on occasions. However, due to the lack of
                      proper marketing techniques, it could not attract
                      travelers. Finally, the hotel owner approached us to
                      promote its business online. With our digital marketing
                      knowledge, we had helped the hotel to grab a strong
                      position.
                    </p>
                    <div className="divCls">
                      <b>Condition when we started the project</b>
                      <ul>
                        <li>
                          The hotel website was not receiving high volume of
                          traffic.
                        </li>
                        <li>It failed to convert visitors into customers.</li>
                        <li>Some customers left negative reviews.</li>
                        <li>
                          The hotel name was not identifiable to travelers
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Problem Statement</b>
                      <ul>
                        <li>Lack of communications</li>
                        <li>No lead generation efforts</li>
                        <li>Very low number of monthly deals</li>
                        <li>The website bounce rate was high.</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Our objective/KPIs</b>
                      <ul>
                        <li>To increase the number of deals throughout a year</li>
                        <li>To spread the hotel’s name across the country</li>
                        <li>To retain the loyal customers</li>
                        <li>To increase the website rank and engagement rate</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>How we achieved it</b>
                      <b>Email and chatbot marketing</b>
                      <p>
                        Although there are modern amenities, the hotel could not
                        retain its customer base. We have found the major reason
                        for this problem is the irregular communications. <br />
                        What we had done to trigger communications -
                      </p>
                      <ul>
                        <li>
                          We installed chatbots in website and social media
                          platforms.
                        </li>
                        <li>
                          Our professionals directly interacted with customers
                          to know their pain points.
                        </li>
                        <li>We focused on every channel for communications.</li>
                        <li>
                          Based on our activities, we created marketing
                          communications document.
                        </li>
                        <li>We also set up an automated email campaign.</li>
                        <li>
                          Our tools send Thank You emails to every new guest
                          booking hotel room.
                        </li>
                        <li>
                          Our marketers continuously send the latest hotel
                          deals.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Lead conversions</b>
                      <p>
                        Although the hotel’s website was receiving some
                        visitors, it could not convert leads into customers. We
                        had applied the most effective lead conversion tactics.
                        Here is what we had done.
                      </p>
                      <ul>
                        <li>
                          We wrote informative and catchy content with blogs and
                          ebooks.
                        </li>
                        <li>
                          Our professionals added some important SEO elements,
                          including page title, internal links, and header.
                        </li>
                        <li>
                          We made the landing page more attractive with videos,
                          images, videos, and CTA buttons.
                        </li>
                        <li>
                          Moreover, we used some premium platform to get
                          database on relevant leads.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Social Media Marketing</b>
                      <p>
                        We think that social platforms are the best destination
                        to find travel enthusiasts looking for accommodations.
                        Our professionals tool some important steps for SMM
                        campaign
                      </p>
                      <ul>
                        <li>
                          We understand the target audience- the type of guests
                          visiting the hotel.
                        </li>
                        <li>
                          Our SMM marketers created social media pages with
                          professional-standard images.
                        </li>
                        <li>
                          We regularly posted images, short videos, and live
                          videos.{" "}
                        </li>
                        <li>
                          We also worked with social media influencers who have
                          high authority in the tourism industry.
                        </li>
                        <li>
                          Our team posted the paid social media ads with the
                          most relevant keywords.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Outcomes</b>
                      <p>
                        Our team members had worked consistently to grow the
                        business in this hospitality industry. We had taken a
                        few months to achieve the desired result. We have
                        presented the result in detail.
                      </p>
                      <ul>
                        <li>125% increase in web traffic</li>
                        <li>More than 110% reservations</li>
                        <li>Over 80% occupancy rate</li>
                        <li>More than 50% increase in revenues.</li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {openPopup && <CaseStudyPopup openCaseStudyPopup={openCaseStudyPopup} />}
    </Layout>
  );
}
