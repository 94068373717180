import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.webp";
import signal from "../../../images/signal.svg";
import telegram from "../../../images/telegram.svg";
import whatsapp from "../../../images/whatsapp.svg";

export default function Header() {
  const headerSticky = () => {
    const header = document.getElementById("app-header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => window.pageYOffset > sticky ? header.classList.add("app-header-sticky") : header.classList.remove("app-header-sticky"));
    return () => window.removeEventListener("scroll", scrollCallBack);
  };
  const downArr = () => <svg><path d="M5 4.33907L8.57741 0.761656C8.90285 0.436219 9.43049 0.436219 9.75592 0.761656C10.0814 1.08709 10.0814 1.61473 9.75592 1.94017L5.58926 6.10683C5.26382 6.43227 4.73618 6.43227 4.41074 6.10683L0.244078 1.94017C-0.0813592 1.61473 -0.0813592 1.08709 0.244078 0.761656C0.569515 0.436219 1.09715 0.436219 1.42259 0.761656L5 4.33907Z" /></svg>;

  useEffect(() => {
    headerSticky();
  }, []);

  return (
    <div className="app-main-header" id="app-header">
      <div className="app-logo">
        <Link to="/"><img src={logo} alt="DigiPrism" /></Link>
      </div>
      <div className="app-navigation">
        <ul>
          <li className="app-down-services">
            <Link to="/services">Services {downArr()}</Link>
            <div className="app-dropdown-services">
              <ul>
                <li>
                  <div className="app-inside-serv-nav">
                    <h3>Digital Marketing</h3>
                    <Link to="/search-engine-ranking">→ &nbsp;Search Engine Ranking (SEO &amp; PPC)</Link>
                    <Link to="/social-media-marketing">→ &nbsp;Social Media Marketing</Link>
                    <Link to="/designing-services">→ &nbsp;Designing Services</Link>
                    <Link to="/content-marketing">→ &nbsp;Content Marketing</Link>
                    <Link to="/influencer-and-affiliate-marketing">→ &nbsp;Influencer &amp; Affiliate marketing</Link>
                    <Link to="/online-reputation-management">→ &nbsp;Online Reputation Management</Link>
                    <Link to="/growth-hacking">→ &nbsp;Growth Hacking</Link>
                    {/* <Link to="/">→ &nbsp;WhatsApp &amp; SMS Marketing</Link>
                    <Link to="/">→ &nbsp;Voice Search</Link> */}
                  </div>
                </li>
                <li>
                  <div className="app-inside-serv-nav">
                    <h3>Development</h3>
                    <Link to="/web-app-developments">→ &nbsp;Web App Development</Link>
                    {/* <Link to="/">→ &nbsp;Mobile Application Development</Link>
                    <Link to="/">→ &nbsp;E-Commerce Applications</Link> */}
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="app-down-indus">
            <span>Industries {downArr()}</span>
            <div className="app-dropdown-industry">
              <ul>
                <li><Link to="/automobile">→ &nbsp;Automobile</Link></li>
                <li><Link to="/insurance">→ &nbsp;Insurance</Link></li>
                <li><Link to="/hospitality">→ &nbsp;Hospitality</Link></li>
                <li><Link to="/real-estate">→ &nbsp;Real Estate</Link></li>
                <li><Link to="/medical-facility">→ &nbsp;Medical Facility</Link></li>
                <li><Link to="/retail-and-ecommerce">→ &nbsp;Retail &amp; Ecommerce</Link></li>
                <li><Link to="/health-supplement">→ &nbsp;Health Supplement</Link></li>
                <li><Link to="/book-release">→ &nbsp;Book Release</Link></li>
                <li><Link to="/textile-and-garments">→ &nbsp;Textile &amp; Garments</Link></li>
                <li><Link to="/lifestyle-and-personal-care">→ &nbsp;Lifestyle &amp; Personal Care</Link></li>
                <li><Link to="/precious-and-semi-precious-jewelry">→ &nbsp;Precious &amp; Semi-Precious Jewelry</Link></li>
                <li><Link to="/consumer-products-and-fmcg">→ &nbsp;Consumer Products &amp; FMCG</Link></li>
              </ul>
            </div>
          </li>
          <li className="app-down-resource">
            <span>Resources {downArr()}</span>
            <div className="app-dropdown-resource">
              <ul>
                <li><Link to="/case-study">→ &nbsp;Case Studies</Link></li>
                <li><Link to="/blogs">→ &nbsp;Blogs</Link></li>
                {/* <li><Link to="/">→ &nbsp;Whitepapers</Link></li>
                <li><Link to="/">→ &nbsp;Newsletter</Link></li>
                <li><Link to="/">→ &nbsp;Podcast</Link></li> */}
              </ul>
            </div>
          </li>
          <li className="app-down-company">
            <span>Company {downArr()}</span>
            <div className="app-dropdown-company">
              <ul>
                <li><Link to="/about-us">→ &nbsp;About Us</Link></li>
                <li><Link to="/our-team">→ &nbsp;Our Team</Link></li>
                <li><Link to="/contact-us">→ &nbsp;Contact Us</Link></li>
              </ul>
            </div>
          </li>
          {/* <li><span>Pricing</span></li> */}
        </ul>
      </div>
      <div className="app-cont-ic">
        <Link to="/contact-us" className="contact-link"><svg viewBox="0 0 24 24"><path d="m15 19c-.552 0-1 .448-1 1v1c0 .552-.448 1-1 1h-10c-.552 0-1-.448-1-1v-18c0-.552.448-1 1-1h.5c.556 0 1.007.454 1 1.012-.007.548.445.988.993.988h4.517c1.044 0 1.496-.44 1.49-.988-.007-.558.444-1.012 1-1.012h.5c.552 0 1 .448 1 1v1c0 .552.448 1 1 1s1-.448 1-1v-1c0-1.657-1.343-3-3-3h-10c-1.657 0-3 1.343-3 3v18c0 1.657 1.343 3 3 3h10c1.657 0 3-1.343 3-3v-1c0-.552-.448-1-1-1z"/><path d="m24 9c0-1.103-.897-2-2-2h-10c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2zm-2 2-4.68 2.18c-.1.05-.21.07-.32.07s-.22-.02-.32-.07l-4.68-2.18v-1.66l5 2.33 5-2.33z"/></svg></Link>
      </div>
      <div className="app-contact">
        <Link to="/contact-us" className="contact-link">Contact Us</Link>
        <a href="https://api.whatsapp.com/send?phone=7890919225" className="whatsapp-link"><img src={whatsapp} alt="whatsapp" /></a>
        <a href="https://api.whatsapp.com/send?phone=7890919225" className="signal-link"><img src={signal} alt="signal" /></a>
        <a href="https://api.whatsapp.com/send?phone=7890919225" className="telegram-link"><img src={telegram} alt="telegram" /></a>
      </div>
      <div className="app-mobile">
        <span>
          <svg x="0px" y="0px" viewBox="0 0 512.001 512.001"><path d="M437.019,74.982C388.667,26.629,324.38,0.001,255.999,0.001S123.333,26.629,74.981,74.982C26.629,123.333,0,187.62,0,256s26.629,132.668,74.981,181.019C123.333,485.372,187.621,512,256.001,512s132.668-26.629,181.019-74.981C485.372,388.668,512.001,324.38,512.001,256S485.371,123.333,437.019,74.982z M255.999,481.158C131.848,481.158,30.843,380.152,30.843,256S131.848,30.843,255.999,30.843S481.156,131.849,481.156,256S380.152,481.158,255.999,481.158z"/><path d="M396.853,240.579H115.147c-8.517,0-15.421,6.905-15.421,15.421c0,8.517,6.905,15.421,15.421,15.421h281.706c8.517,0,15.421-6.905,15.421-15.421C412.274,247.483,405.37,240.579,396.853,240.579z"/><path d="M376.291,155.76H135.709c-8.517,0-15.421,6.905-15.421,15.421c0,8.517,6.905,15.422,15.421,15.422h240.582c8.517,0,15.422-6.905,15.422-15.422C391.713,162.665,384.808,155.76,376.291,155.76z"/><path d="M376.291,325.397H135.709c-8.517,0-15.421,6.905-15.421,15.421c0,8.517,6.905,15.421,15.421,15.421h240.582c8.517,0,15.422-6.905,15.422-15.421C391.713,332.301,384.808,325.397,376.291,325.397z"/></svg>
        </span>
        <ul>
          <li>
            <p><Link to="/services">Services</Link></p>
            <div className="app-mob-drp-dwn">
              <h3>Digital Marketing</h3>
              <Link to="/search-engine-ranking">→ &nbsp;Search Engine Ranking (SEO &amp; PPC)</Link>
              <Link to="/social-media-marketing">→ &nbsp;Social Media Marketing</Link>
              <Link to="/designing-services">→ &nbsp;Designing Services</Link>
              <Link to="/content-marketing">→ &nbsp;Content Marketing</Link>
              <Link to="/influencer-and-affiliate-marketing">→ &nbsp;Influencer &amp; Affiliate marketing</Link>
              <Link to="/online-reputation-management">→ &nbsp;Online Reputation Management</Link>
              <Link to="/growth-hacking">→ &nbsp;Growth Hacking</Link>
            </div>
            <div className="app-mob-drp-dwn">
              <h3>Development</h3>
              <Link to="/web-app-developments">→ &nbsp;Web App Development</Link>
              {/* <Link to="/">→ &nbsp;Mobile Application Development</Link>
              <Link to="/">→ &nbsp;E-Commerce Applications</Link> */}
            </div>
          </li>
          <li>
            <p>Industries</p>
            <div className="app-mob-drp-dwn">
              <Link to="/automobile">→ &nbsp;Automobile</Link>
              <Link to="/insurance">→ &nbsp;Insurance</Link>
              <Link to="/hospitality">→ &nbsp;Hospitality</Link>
              <Link to="/real-estate">→ &nbsp;Real Estate</Link>
              <Link to="/medical-facility">→ &nbsp;Medical Facility</Link>
              <Link to="/retail-and-ecommerce">→ &nbsp;Retail &amp; Ecommerce</Link>
              <Link to="/health-supplement">→ &nbsp;Health Supplement</Link>
              <Link to="/book-release">→ &nbsp;Book Release</Link>
              <Link to="/textile-and-garments">→ &nbsp;Textile &amp; Garments</Link>
              <Link to="/lifestyle-and-personal-care">→ &nbsp;Lifestyle &amp; Personal Care</Link>
              <Link to="/precious-and-semi-precious-jewelry">→ &nbsp;Precious &amp; Semi-Precious Jewelry</Link>
              <Link to="/consumer-products-and-fmcg">→ &nbsp;Consumer Products &amp; FMCG</Link>
            </div>
          </li>
          <li>
            <p>Company</p>
            <div className="app-mob-drp-dwn">
              <Link to="/about-us">→ &nbsp;About Us</Link>
              <Link to="/our-team">→ &nbsp;Our Team</Link>
              <Link to="/contact-us">→ &nbsp;Contact Us</Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
