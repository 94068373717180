import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import smokeVid from "../../../images/smoke.mp4";
import HomePopup from './HomePopup';

export default function HeroSlider() {
  const pathRef = useRef();
  useEffect(() => {
    let svgPathElement = pathRef.current;
    let length = svgPathElement.getTotalLength();
    svgPathElement.style.strokeDasharray = length;
    svgPathElement.style.strokeDashoffset = length;
    const lineAnimation = () => {
      let docElement = document.documentElement;
      let scrollpercent =
        (document.body.scrollTop + docElement.scrollTop + 0) /
        (docElement.scrollHeight - docElement.clientHeight);
      let draw = length * scrollpercent;
      svgPathElement.style.strokeDashoffset = length + draw * 10;
    };
    window.addEventListener("scroll", lineAnimation);
  }, []);

  const [showPop, setShowPop] = useState(true);
  const [showHomePopup, setShowHomePopup] = useState(false);

  const openFlashPop = () => {
    setShowPop(!showPop);
  };

  const openHomePopup = () => {
    setShowPop(false);
    setShowHomePopup(!showHomePopup);
  }

  return (
    <div className="app-heroSlider">
      <div className="app-hero-cover-slider">
        <div className="app-container">
          <div className="app-center-content">
            <h1>India’s First Industry Specific</h1>
            <p>Digital Marketing Consultancy</p>
          </div>
        </div>
      </div>
      <div className="app-sliderBanner">
        <video autoPlay loop muted>
          <source src={smokeVid} type="video/mp4" />
        </video>
      </div>
      <div className="app-mouse-scroll"></div>
      <span className="app-slider-bottom-effect"></span>
      <div className={showPop === true ? "app-hrs-slider app-slid-show" : "app-hrs-slider"}>
        {showPop === false && <span onClick={() => openFlashPop()}>←</span>}
        <p onClick={() => openHomePopup()}>
          Free Digital Marketing Proposal in <b>17 Hours</b>
        </p>
        <b onClick={() => openFlashPop()}>
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 511.995 511.995">
            <path d="M437.126,74.939c-99.826-99.826-262.307-99.826-362.133,0C26.637,123.314,0,187.617,0,256.005s26.637,132.691,74.993,181.047c49.923,49.923,115.495,74.874,181.066,74.874s131.144-24.951,181.066-74.874C536.951,337.226,536.951,174.784,437.126,74.939z M409.08,409.006c-84.375,84.375-221.667,84.375-306.042,0c-40.858-40.858-63.37-95.204-63.37-153.001s22.512-112.143,63.37-153.021c84.375-84.375,221.667-84.355,306.042,0C493.435,187.359,493.435,324.651,409.08,409.006z" />
            <path d="M341.525,310.827l-56.151-56.071l56.151-56.071c7.735-7.735,7.735-20.29,0.02-28.046c-7.755-7.775-20.31-7.755-28.065-0.02l-56.19,56.111l-56.19-56.111c-7.755-7.735-20.31-7.755-28.065,0.02c-7.735,7.755-7.735,20.31,0.02,28.046l56.151,56.071l-56.151,56.071c-7.755,7.735-7.755,20.29-0.02,28.046c3.868,3.887,8.965,5.811,14.043,5.811s10.155-1.944,14.023-5.792l56.19-56.111l56.19,56.111c3.868,3.868,8.945,5.792,14.023,5.792c5.078,0,10.175-1.944,14.043-5.811C349.28,331.117,349.28,318.562,341.525,310.827z" />
          </svg>
        </b>
      </div>
      <div className="app-info-slide">
        <Link to="/">
          Here's Your <span>Free Website Audit Report</span>
        </Link>
      </div>
      <div className="app-lineAnimation">
        <svg width="509" height="642" viewBox="0 0 509 642" fill="none">
          <path
            fill="none"
            ref={pathRef}
            stroke="#ffffff"
            strokeWidth="6"
            strokeLinecap="round"
            d="M500.31 640C478.31 624 500.31 528 500.31 528C560.31 312 210.31 370 210.31 370C-7.69 388 2.31 228 2.31 228C-3.84 135 82.31 90 82.31 90C82.31 90 142.16 55 154.31 2"
          />
        </svg>
      </div>
      {showHomePopup && <HomePopup openHomePopup={openHomePopup} />}
    </div>
  );
}
