import React, { useState } from 'react';
import { basepath } from '../core/config';

export default function Login() {
  const [initValue, setInitValue] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    event.preventDefault();
    setInitValue({
      ...initValue,
      [event.target.name]: event.target.value,
    });
  };

  const tryLogin = async (formData) => {
    const configdata = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };
    fetch(`${basepath}/users/login`, configdata)
      .then((res) => res.json())
      .then((res) => {
        if (res && res.token) {
          localStorage.setItem('authToken', res.token);
        }
        window.location.href = '/admin/dashboard';
      })
      .catch((err) => console.log(err));
  };

  const handleLogin = () => {
    let getFormData = {
      email: initValue.email,
      password: initValue.password,
    };
    tryLogin(getFormData);
  };

  return (
    <div className='app-backend'>
      <div className='app-wrapper-cover-backend'>
        <p>Login to continue...</p>
        <form className='app-formArea'>
          <div className='app-frmField'>
            <b>Email</b>
            <input type='email' name='email' onChange={(event) => handleChange(event)} value={initValue.email} />
          </div>
          <div className='app-frmField'>
            <b>Password</b>
            <input
              type='password'
              name='password'
              onChange={(event) => handleChange(event)}
              value={initValue.password}
            />
          </div>
          <div className='app-frmField'>
            <span onClick={() => handleLogin()}>Login</span>
          </div>
        </form>
      </div>
    </div>
  );
}
