import React from "react";
import { Helmet } from "react-helmet";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from 'react-router-dom';
import caseImg from "../../../images/casestudy/cs-insurance.png";
import img1 from "../../../images/industry-banners/top/2-1.jpg";
import img2 from "../../../images/industry-banners/top/2-2.jpg";
import img3 from "../../../images/industry-banners/top/2-3.jpg";
import Layout from "../../core/Layout";
import CaseStudyPopup from "../services/CaseStudyPopup";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function Insurance() {
  window.scrollTo(0, 0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const openCaseStudyPopup = () => {
    setOpenPopup(!openPopup);
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>Digital Marketing for Insurance Industry | DigiPrism</title>
        <meta name="description" content="Low organic rank? Inconsistent traffic? Get solution with Paid Advertising, Interactive Content, and Video Marketing. Book A Free Digital Marketing Demo Now!" />
      </Helmet>
      <div className="app-banner-indus insurance">
        <div className="app-container">
          <div className="app-inside-wrapper">
            <h2>
              DigiPrism <span>Insurance</span>
            </h2>
            <p>What's bothering you? Disrupted supply-chain? Loss of consumer <br />trust? Low value deals?</p>
            <p>Trust us; Digital Marketing has solutions for all of these problems.<br />
            Shall we begin?</p>
            <Link to="/contact-us" className="app-tell-us">Get A Free Consultation</Link>
          </div>
        </div>
      </div>
      <div className="app-indust-head-all">
        <h3>Your Problems... Sorted!</h3>
      </div>
      <div className="app-left-industry-area insurance">
        <div className="app-container">
          <div className="app-flx-wrapp">
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>1.</span>
                Low organic rank
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Crawl errors</li>
                    <li>Lack of content distribution</li>
                    <li>Duplicate content </li>
                    <li>Lack of trust </li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>URL adjustment </li>
                    <li>Fresh content development</li>
                    <li>E-books and brochures published</li>
                    <li>Video marketing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>2.</span>
                Loss of customers’ interest
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Irregular blogging</li>
                    <li>Lack of communication</li>
                    <li>No SMM result</li>
                    <li>Business reach problems</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Shareable content development</li>
                    <li>Instant messaging marketing</li>
                    <li>Viral marketing</li>
                    <li>Micro and macro-influencer marketing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>3.</span>Inconsistent traffic
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>Why this happens ?</h6>
                  <ul>
                    <li>Slow business growth</li>
                    <li>Advertising problems</li>
                    <li>Low customer feedback</li>
                    <li>Error message</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Affiliate marketing</li>
                    <li>Banner ads</li>
                    <li>Drip marketing campaign</li>
                    <li>Google penalty recovery</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>4.</span>Loss of ranking
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Crawl errors</li>
                    <li>Inactive pages</li>
                    <li>No effective link building</li>
                    <li>Negative user interactions</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Technical SEO solutions</li>
                    <li>Outdated page removal</li>
                    <li>Backlinks development</li>
                    <li>User behavior analysis</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-three-channel">
        <div className="app-container">
          <div className="app-top-heading-cov insurance">
            <h3>
              Top 3 Marketing Channels for <br />
              <span>Insurance Industry</span>
            </h3>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Programmatic Advertising</h4>
                <p>
                  With Programmatic Advertising in Marketing, we automate the
                  online selling process across different websites and different
                  platforms by the most cost and energy effective manner. Our
                  Programmatic Advertising partner uses the most advanced and
                  ever-growing database to include the most number of channels
                  and platforms.
                </p>
              </div>
              <span className="app-topright"><img src={img1} alt="Programmatic Advertising" /></span>
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M5 1C73 9 141 78.5 130 183M130 183L141 170.5M130 183L119 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="-0.00012207"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-rgt">
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <span className="app-topleft"><img src={img2} alt="Quantum Computing" /></span>
              <div className="app-topright">
                <h4>Quantum Computing</h4>
                <p>
                  As insurance is a business domain that requires numerical
                  accuracy like very few other domains, marketing campaigns
                  regulated with Quantum Computing is our best bet. Quantum
                  Computing helps secure your marketing figures and to
                  accurately capture the market trend forecasting.
                </p>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Interactive Content</h4>
                <p>
                  Interactive Content marketing is a scientific way of inbound
                  marketing where the brand interact with each customer as per
                  their preferred line of communication with the help of their
                  past browsing and purchasing behaviour. It manifolds the
                  instant offers and discounts success because the customer
                  themselves hint at the type of incentives they will like.
                </p>
              </div>
              <span className="app-topright"><img src={img3} alt="Interactive Content" /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="app-scroll-items-cover wrapp-tree-two">
        <div className="app-container">
          <div className="app-scrl-wrapper">
            <div className="app-spl-latest-left">
              <span>
                <img src={caseImg} alt="case study" />
                <div className="download-btn" onClick={() => openCaseStudyPopup()}>Download</div>
              </span>
              <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                <g filter="url(#filter0_d)">
                  <path
                    d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="6 7.9"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="-9.15527e-05"
                    width="146"
                    height="192"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <p><Link to="/case-study">More Case Study</Link></p>
            </div>
            <div className="app-spl-latest-right">
              <span>
                <svg viewBox="0 0 351.128 351.128">
                  <path d="M72.326,147.33c4.284-26.928,37.944-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.224-0.612,2.448-1.224,3.06-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.976,11.628C38.054,85.518,1.946,136.313,3.782,184.662c-6.12,32.437-4.896,67.32,4.284,96.084c6.12,18.36,23.868,27.54,42.228,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.524-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C115.778,165.078,66.818,181.602,72.326,147.33z" />
                  <path d="M274.286,147.33c4.284-26.928,37.943-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.225-0.612,2.448-1.224,3.061-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.977,11.628c-35.496,29.376-71.604,80.172-69.768,128.52c-6.12,32.437-4.896,67.32,4.283,96.084c6.12,18.36,23.868,27.54,42.229,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.523-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C317.738,165.078,268.166,181.602,274.286,147.33z" />
                </svg>
              </span>
              <h6>
                Rajib and Monalisa introduced my brand to 17 influencer. 6 of
                them still work for my brand. Their negotiations are magic.
              </h6>
              <b>S. S. Mittal</b>
              <p>Co-Owner, Droob Global Homes</p>
              <div className="app-bg-dot">
                <svg viewBox="0 0 266 246" fill="none">
                  <circle cx="3" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="63" r="3" fill="#F7F7F7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-faq-cover">
        <div className="app-container">
          <div className="app-faq-wrap">
            <div className="app-top-heading-cov insurance">
              <h3>
                Frequently Asked <span>Questions</span>
              </h3>
            </div>
            <div className="app-faq-wrapper insurance">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      We are startups as insurance providers. How do we get
                      leads to our website?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      As you are a startup, we will begin our approach with
                      Ideal Customer Profile creation.
                    </p>
                    <p>
                      Besides, we will draw valuable traffic to your website
                      with PPC advertising. The next thing will be our database
                      Emailing campaign to nurture your leads. Our Cold mailing
                      and Social Selling tricks will also trigger your insurance
                      policy sales.
                    </p>
                    <p>
                      What’s more, our Multi-level Inbound and Outbound
                      Marketing coupled with marketing automation will help you
                      to get values.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I am an insurance agent and I need to reach lots of
                      potential clients? How do you help me with communication?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Our experience says, Chatbot Marketing works here the best
                      combining cutting-edge Social Media Marketing techniques
                      to give you the best blend. Our chatbot builders
                      collaborate with marketers for Facebook Messenger
                      marketing.
                    </p>
                    <p>
                      Personalized Greeting and Welcome Dialogue box by
                      DigiPrism’s proficient developers will help you capture
                      your most potential leads with minimal friction.
                    </p>
                    <p>
                      Mentioning brand-bot in your customer assistance channels
                      gives particularly good results. This method works quite
                      well for acquiring more leads and gaining higher revenues.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      I am now getting many results from my email marketing
                      campaign for my insurance service. Can you make my
                      campaign profitable?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We choose the best ESP for the email marketing program.
                      Our Marketing Automation tool helps us in saving your cost
                      and time. With Dynamic Content and Multivariate testing,
                      DigiPrism is committed to deliver the best outcome to your
                      campaign.
                    </p>
                    <p>
                      Moreover, thanks to our special Email Personalization
                      technique, you can find high Click-Through-Rate. We ensure
                      high Email Deliverability and low Bounce Rate.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      My insurance website is not getting high visibility. How
                      will you help me to solve problems?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We apply display advertising, paid search, and social
                      media advertising techniques to build your brand and make
                      your website visible. Our professionals choose High
                      Commercial Intent Keywords to place them in your content.
                    </p>
                    <p>
                      Moreover, our data-driven Long-Form Content and Content
                      Marketing solutions will be useful. We also provide you
                      with Schema Microdata, Internal Linking, and several other
                      services to strengthen your website visibility.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      There is a high bounce rate on my insurance website. How
                      will I lower this rate for my insurance business website?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Let us first redesign your UI and check for the content
                      readability. Our team has added Informational and
                      Commercial Keywords. Moreover, our Query String Removal,
                      Image Compression, and Landing Page Redesign will enable
                      you to reach the target audience.
                    </p>
                    <p>
                      Our responsive web designers collaborates with the
                      marketing team to provide every customer with the desired
                      solution. Our Brand Storytelling technique in social media
                      platforms will let you get more leads and engage the
                      present customer base. You can gain the trust of your
                      potential customers.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How will I educate my potential customers about my
                      insurance policies?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Our digital marketers say, Content Marketing, YouTube
                      Marketing, and Viral Marketing works the best to reveal
                      your product details.
                    </p>
                    <p>
                      We suggest adding an FAQ page and structured data to your
                      website. Our Persona-based content and Customer Journey
                      Mapping will help us deliver the best content. Our
                      marketers use comprehensible content for Educational Blogs
                      and Videos. You can educate your target audience in the
                      best way.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Show me a detailed Insurance success story.
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="divCls">
                      <b>About the company</b>
                      It was a startup in the insurance field, and now, it has
                      gained a prominent insurance agency in India. Due to the
                      lack of customers, the insurance provider teamed up with
                      our digital marketers. During the origin of the company,
                      it used to provide only health insurance. In due course,
                      it started growing its business to offer automobile
                      insurance, travel insurance, and property insurance. Our
                      digital marketing, reputation management, and other
                      activities had helped the company to thrive more.
                    </p>
                    <div className="divCls">
                      <b>Condition when we started the project</b>
                      <ul>
                        <li>
                          Inability to reach potential customers outside the
                          city{" "}
                        </li>
                        <li>Lack of regional footprint</li>
                        <li>Lead generation declining</li>
                        <li>Digital ads were ineffective</li>
                        <li>No skillful agents to increase sales</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Problem statement</b>
                      <ul>
                        <li>Changing demographics</li>
                        <li>No regularity in delivery of messages</li>
                        <li>High Acquisition Cost</li>
                        <li>Problem in educating clients</li>
                        <li>Lack of business reach</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Objectives/KPIs</b>
                      <ul>
                        <li>
                          To bring the company’s name in front of the target
                          audience
                        </li>
                        <li>To increase the ranking in the SERP result</li>
                        <li>To raise the volume of qualified leads</li>
                        <li>To drive business growth</li>
                        <li>To ensure optimal result from ads</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Email marketing</b>
                      <p>
                        The communication strategy of the company was very weak,
                        and that’s why we started an email marketing, direct
                        mail, and drip email campaign.
                      </p>
                      <ul>
                        <li>
                          We employed tools for call tracking and leads
                          tracking.
                        </li>
                        <li>
                          Our professionals created a database of a list of
                          subscribers.
                        </li>
                        <li>
                          We selected an advanced platform to send automated
                          email response
                        </li>
                        <li>
                          Our content developers regularly send emails on the
                          latest insurance deals.
                        </li>
                        <li>
                          We created responsive emails to ensure a high open
                          rate.
                        </li>
                      </ul>
                      <p>
                        Our email marketing had fostered communication in the
                        insurance agency. Presently, they receive queries from
                        several leads.
                      </p>
                    </div>
                    <div className="divCls">
                      <b>Display advertising campaign</b>
                      <p>
                        The insurance company’s website was not receiving
                        valuable traffic, and that’s why the conversion rate was
                        very low. We started a multi-layered ad campaign with
                        display advertisements. Have a look at what we had done.
                      </p>
                      <ul>
                        <li>We took a few days to research audiences.</li>
                        <li>
                          With location-based, demographic-based, and
                          retargeting ads, we reached the target audience.
                        </li>
                        <li>
                          We created the most compelling banner ads for the
                          website and other platforms.
                        </li>
                        <li>
                          Our short and clear message attracted several viewers.{" "}
                        </li>
                        <li>
                          We worked on the landing page design and repositioned
                          the CTA and other visual elements.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Social media marketing</b>
                      <p>
                        The purpose of starting SMM campaign was to educate the
                        potential customers. That is why we took steps to
                        publish informative content.
                      </p>
                      <ul>
                        <li>
                          We created professional-looking Facebook and Twitter
                          pages.
                        </li>
                        <li>
                          Our team had consulted with the in-house team of the
                          company to learn about their insurance packages.
                        </li>
                        <li>It helped us in creating informative video.</li>
                        <li>
                          Our professionals made a schedule for video
                          publication on the social platforms.
                        </li>
                        <li>
                          We also published blogs and trigged conversation among
                          social media followers.
                        </li>
                      </ul>
                      <p>
                        Our efforts had stimulated much response from these
                        followers.
                      </p>
                    </div>
                    <div className="divCls">
                      <b>Outcomes</b>
                      <p>
                        Our sincerity and commitments have reached our client’s
                        business to a different level. We have increased the
                        profit margin of the company. We have briefly shown what
                        we have achieved.
                      </p>
                      <ul>
                        <li>More than 25K Twitter and Facebook impressions</li>
                        <li>Over 70% email open rates</li>
                        <li>50% in the sales revenues in 6 months</li>
                        <li>200% increase in monthly visitors</li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {openPopup && <CaseStudyPopup openCaseStudyPopup={openCaseStudyPopup} />}
    </Layout>
  );
}
