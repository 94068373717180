import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import abimg1 from "../../../images/about/1.svg";
import abimg10 from "../../../images/about/10.svg";
import abimg11 from "../../../images/about/11.svg";
import abimg12 from "../../../images/about/12.svg";
import abimg13 from "../../../images/about/13.svg";
import abimg14 from "../../../images/about/14.svg";
import abimg2 from "../../../images/about/2.svg";
import abimg3 from "../../../images/about/3.svg";
import abimg4 from "../../../images/about/4.svg";
import abimg5 from "../../../images/about/5.svg";
import abimg6 from "../../../images/about/6.svg";
import abimg7 from "../../../images/about/7.svg";
import abimg8 from "../../../images/about/8.svg";
import abimg9 from "../../../images/about/9.svg";
import Layout from "../../core/Layout";
import HomePopup from '../home/HomePopup';
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function About() {
  window.scrollTo(0, 0);

  const [showHomePopup, setShowHomePopup] = useState(false);
  const openHomePopup = () => { setShowHomePopup(!showHomePopup); }

  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>About Us | DigiPrism Marketing Consultancy</title>
        <meta name="description" content="India’s First Industry-Specific Digital Marketing Consultancy | Internet Advertising | We Are A Global Establishment | Team Members from 6 States" />
      </Helmet>
      <div className="app-about-content-cover">
        <div className="app-container">
          <div className="app-wrap-cover-page">
            <h2>About Us</h2>
            <p>
              We feel pride in breaking through our growth plateau <br />
              and striking out the digitally disbalanced world.
            </p>
            <span onClick={() => openHomePopup()}>Get A Free Consultation</span>
          </div>
        </div>
      </div>
      <div className="app-about-container">
        <div className="app-container">
          <div className="app-content-about">
            <h4>There Is No Border And Boundary In The Digital Age</h4>
            <h5>
              DigiPrism is a platform <br />
              Built on this Universally Accepted Truth.
            </h5>
            <p>
              Have you ever noticed refracted lights from an optical prism?
              DigiPrism also reflects a ray of hope to businesses by creating a
              DIGITAL PRISM. We are analog creatures living in the world of
              digital technologies. We guide others to move through the digital
              orbit removing all the obstacles.
            </p>
          </div>
          <div className="app-open-pop-for-email"></div>
        </div>
      </div>
      <div className="app-about-content-spl">
        <div className="app-container">
          <div className="app-about-content-meagure">
            <h3>A MEAGURE START</h3>
            <p>
              The backdrop was a generic IT Company. The time was between
              end-2011 to mid-2015. The industry was going gaga over Marketing
              Automation, Behavioral Marketing, Context-Rich Systems, The
              Internet of Things &amp; Co-Marketing trends, We too made the most out of it &amp; Made
              &nbsp;<Link to="/our-team">our team</Link> the ‘Best Performing Team: Marketing Innovation’ for
              consecutive 3 years.
            </p>
            <p>
              Then you know, that feeling when inspite of everything going
              marvellously well, you feel like you are born for something else?
              Yes; exactly that feeling struck us. We resigned; We Strated&nbsp;
              <Link to="/contact-us">
                DIGIPRISM: India’s First Industry-Specific Digital Marketing
                Anency.
              </Link>
            </p>
          </div>
          <div className="app-spl-cont-about">
            <div className="app-miss-about">
              <h3>MISSION</h3>
              <p>
                To assist businesses, grow exponentially via digitally quite
                literally.
              </p>
            </div>
            <div className="app-miss-about">
              <h3>Vision</h3>
              <p>
                To create a disruptive environment in digital space through
                creativity and high quality.
              </p>
            </div>
          </div>
          <div className="app-about-content-meagure">
            <h3>THE INITIAL DASH</h3>
            <p>
              5 years fast-forward; 40+ tycoons have believed us with their
              businesses. We learned the way of enriching our knowledge with
              innovative technologies. At the same time, we enabled global
              enterprises to unleash the potential of the digital world. Our
              motto was to make them reimagine their business values to drive
              velocity.
            </p>
            <p>
              We feel pride in breaking through our growth plateau and striking
              out the digitally disbalanced world. Today, we have become one of
              the most comprehensive consultancies with a full suite of digital
              offerings-
            </p>
            <h4>Digital Merketing</h4>
            <ul>
              <li><Link to="/search-engine-ranking"><span><img src={abimg1} alt="Search Engine Optimization" /></span>Search Engine <br />Optimization</Link></li>
              <li><Link to="/social-media-marketing"><span><img src={abimg2} alt="Search Engine Marketing" /></span>Search Engine<br /> Marketing</Link></li>
              <li><Link to="/search-engine-ranking"><span><img src={abimg3} alt="Pay Per Click/Google Ads" /></span>Pay Per Click/<br />Google Ads</Link></li>
              <li><Link to="/content-marketing"><span><img src={abimg4} alt="Content marketing" /></span>Content <br />marketing</Link></li>
              <li><Link to="/social-media-marketing"><span><img src={abimg5} alt="Social Media Marketing" /></span>Social Media <br />Marketing</Link></li>
              <li><Link to="/growth-hacking"><span><img src={abimg6} alt="Email Marketing" /></span>Email <br />Marketing</Link></li>
              <li><Link to="/influencer-and-affiliate-marketing"><span><img src={abimg7} alt="Influencer and Affiliates" /></span>Influencer and <br />Affiliates</Link></li>
              <li><Link to="/designing-services"><span><img src={abimg8} alt="Graphic designing" /></span>Graphic <br />designing</Link></li>
              <li><Link to=""><span><img src={abimg9} alt="WhatsApp &amp; SMS Marketing" /></span>WhatsApp &amp; <br />SMS Marketing</Link></li>
              <li><Link to=""><span><img src={abimg10} alt="Voice Search" /></span>Voice <br />Search</Link></li>
            </ul>
            <h4>Software Development</h4>
            <ul>
              <li><Link to="/web-app-developments"><span><img src={abimg11} alt="Web Application" /></span>Web <br />Application</Link></li>
              <li><Link to="/web-app-developments"><span><img src={abimg12} alt="Mobile Application" /></span>Mobile <br />Application</Link></li>
              <li><Link to=""><span><img src={abimg13} alt="E-Commerce Application" /></span>E-Commerce <br />Application</Link></li>
            </ul>
            <h4>Business Consultation</h4>
            <ul>
              <li><Link to=""><span><img src={abimg14} alt="Business Consultation" /></span>Business <br />Consultation</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="app-about-proud-content">
        <div className="app-container">
          <div className="app-about-content-proud">
            <h6>NUMBERS WE ARE PROUD OF</h6>
            <ul>
              <li>
                <span>42+</span>
                <p>Successful Project</p>
              </li>
              <li>
                <span>08+</span>
                <p>Business Domains</p>
              </li>
              <li>
                <span>INR 30L</span>
                <p>Business</p>
              </li>
              <li>
                <span>15</span>
                <p>Start-Ups</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="app-about-drive-content">
        <div className="app-container">
          <div className="app-about-content-drive">
            <h6>OUR DRIVERS</h6>
            <ul>
              <li>TEAMWORK</li>
              <li>TRANSPARENCY</li>
              <li>INNOVATION</li>
              <li>CREATIVITY</li>
              <li>FLEXIBLIBILTY</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="app-about-global-content">
        <div className="app-container">
          <div className="app-about-content-global">
            <h3>GROUND RULE</h3>
            <p>
              As a part of our social responsibility, we always lend our hand to
              the LGBT community. During our recruitment, we always follow our
              non-discrimination policies. To us, everyone must have equal
              rights to financial freedom. Our aim is to develop one of the
              LGBTQ-friendly companies to grow all; grow with all.
            </p>
          </div>
          <div className="app-about-content-global">
            <h3>CSR</h3>
            <p>
              We at DigiPrism believe that we are defined by what we give back
              to the society.
            </p>
            <p>
              DigiPrism Marketing Consultancy serves its social responsibility
              two ways: compliance and proactiveness.
            </p>
            <p>
              Compliance refers to our commitment towards legal adherence and
              community values. Proactiveness inspires us to donate 2% of our
              annual revenue towards the welfare of child labour victims. This
              money is circulated in association with Child Rights And You (CRY)
              for the children’s better education and safety.
            </p>
            <p>
              This policy applies to DigiPrism Marketing Consultancy and its
              subsidiaries. It may also refer to suppliers and partners.
            </p>
          </div>
          <div className="app-about-content-global">
            <h3>MILES TO GO</h3>
            <p>
              DigiPrism will go on reflecting the rays of success, as we are
              armed with high-energy teams and diverse perspectives.
            </p>
          </div>
        </div>
        <div className="app-circle">
          <div className="app-cir-wrap">
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
            <div className="c"></div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {showHomePopup && <HomePopup openHomePopup={openHomePopup} />}
    </Layout>
  );
}
