import React from "react";
import { Helmet } from "react-helmet";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from 'react-router-dom';
import caseImg from "../../../images/casestudy/cs-lifestylepersonalcare.png";
import img1 from "../../../images/industry-banners/top/10-1.jpg";
import img2 from "../../../images/industry-banners/top/10-2.jpg";
import img3 from "../../../images/industry-banners/top/10-3.jpg";
import Layout from "../../core/Layout";
import CaseStudyPopup from "../services/CaseStudyPopup";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";

export default function LifestylePersonal() {
  window.scrollTo(0, 0);
  const [openPopup, setOpenPopup] = React.useState(false);
  const openCaseStudyPopup = () => {
    setOpenPopup(!openPopup);
  };
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>DigiPrism | Best Digital Marketing For Personal Care Brand</title>
        <meta name="description" content="Personal Care Industry runs on customer trust. Beat your competitors with Social Media, Influencer Marketing, and Content Marketing. Book A Free Demo Now!" />
      </Helmet>
      <div className="app-banner-indus lifestyle">
        <div className="app-container">
          <div className="app-inside-wrapper">
            <h2>
              DigiPrism{" "}
              <span>
                Lifestyle &amp; <br />
                Personal Care
              </span>
            </h2>
            <p>
              In the last 8 years, We’ve helped 20+ Lifestyle &amp; Personal
              Care <br />brands with these 
              problems most frequently.
            </p>
            <p>Tell us what we can help you with!</p>
            <Link to="/contact-us" className="app-tell-us">Get A Free Consultation</Link>
          </div>
        </div>
      </div>
      <div className="app-indust-head-all">
        <h3>Your Problems... Sorted!</h3>
      </div>
      <div className="app-left-industry-area lifestyle">
        <div className="app-container">
          <div className="app-flx-wrapp">
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>1.</span>
                Low conversion rates
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Improper and unattractive CTA</li>
                    <li>Not communicating values</li>
                    <li>Messy web design</li>
                    <li>Outdated copy </li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>CTA redesigning and proper placement</li>
                    <li>Unique Value Proposition testing</li>
                    <li>Repositioning visual elements</li>
                    <li>Keyword-rich copywriting</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>2.</span>
                Audience Issues
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Slow page loading</li>
                    <li>Low social media followers</li>
                    <li>Low ROI</li>
                    <li>Lack of local customers</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Technical website audit</li>
                    <li>Social media PPC </li>
                    <li>Customer-focused paid advertising</li>
                    <li>GMB design and directory listing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading">
                <span>3.</span>Stiff SEO ranking competition
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Website loading errors</li>
                    <li>Low organic traffic</li>
                    <li>Irrelevant content</li>
                    <li>Low search engine visibility</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>CSS and HTML file minimization</li>
                    <li>Adding internal hyperlinks</li>
                    <li>Content-based keyword incorporation</li>
                    <li>Add Meta description and titles</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-inside-wrapper-item">
              <h4 className="app-indus-heading right-align">
                <span>4.</span>Ineffective social media marketing
              </h4>
              <div className="app-in-cover-area">
                <div className="app-in-left-cov">
                  <h6>What happens?</h6>
                  <ul>
                    <li>Improper SMM scheme</li>
                    <li>No sale from social media</li>
                    <li>Lack of authority</li>
                    <li>Lack of integration</li>
                  </ul>
                </div>
                <div className="app-in-right-cov">
                  <h6>How we solve</h6>
                  <ul>
                    <li>Reverse engineering SMM efforts </li>
                    <li>Loyal audience base development</li>
                    <li>Influencer marketing</li>
                    <li>Social icons redesigned</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-top-three-channel">
        <div className="app-container">
          <div className="app-top-heading-cov lifestyle">
            <h3>
              Top 3 Marketing Channels for <br />
              <span>Lifestyle &amp; Personal Care Industry</span>
            </h3>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Influencer Marketing</h4>
                <p>
                  Influencer marketing is about spreading brand message by
                  working with the key industry leaders. Influencers work as the
                  loyal brand advocates. Successful influencer marketing builds
                  customer trust, targets the right audience, and boost the
                  search rankings. A proper influencer identification strategy
                  gives you a higher return.
                </p>
              </div>
              <span className="app-topright"><img src={img1} alt="img" /></span>
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M5 1C73 9 141 78.5 130 183M130 183L141 170.5M130 183L119 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="-0.00012207"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-rgt">
              <div className="app-line-top">
                <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeDasharray="6 7.9"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="-9.15527e-05"
                      width="146"
                      height="192"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <span className="app-topleft"><img src={img2} alt="img" /></span>
              <div className="app-topright">
                <h4>Social Commerce</h4>
                <p>
                  Social commerce refers to the use of social networking
                  platform to sell services/products. The level of consumer
                  interactions is the success metrics of social commerce. The
                  campaign connects potential shoppers with your business. You
                  get the chance to use social platform as a customer service
                  channel.
                </p>
              </div>
            </div>
          </div>
          <div className="app-top-three-inside">
            <div className="app-topthree-item app-lft">
              <div className="app-topleft">
                <h4>Omnichannel Marketing</h4>
                <p>
                  Omnichannel marketing refers to the multichannel sales
                  approach. It ensures better and higher customer experience
                  ratings. The perfect omnichannel strategy attracts your
                  present audience and reaches your content to the wider
                  audience. It also ensures seamless experience for customers
                  using mobiles, desktops, and other devices for the purchase.
                </p>
              </div>
              <span className="app-topright"><img src={img3} alt="img" /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="app-scroll-items-cover wrapp-tree-four">
        <div className="app-container">
          <div className="app-scrl-wrapper">
            <div className="app-spl-latest-left">
              <span>
                <img src={caseImg} alt="case study" />
                <div className="download-btn" onClick={() => openCaseStudyPopup()}>Download</div>
              </span>
              <svg width="146" height="192" viewBox="0 0 146 192" fill="none">
                <g filter="url(#filter0_d)">
                  <path
                    d="M141 1C73 9 5 78.5 16 183M16 183L5 170.5M16 183L27 170.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="6 7.9"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="-9.15527e-05"
                    width="146"
                    height="192"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <p><Link to="/case-study">More Case Study</Link></p>
            </div>
            <div className="app-spl-latest-right">
              <span>
                <svg viewBox="0 0 351.128 351.128">
                  <path d="M72.326,147.33c4.284-26.928,37.944-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.224-0.612,2.448-1.224,3.06-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.976,11.628C38.054,85.518,1.946,136.313,3.782,184.662c-6.12,32.437-4.896,67.32,4.284,96.084c6.12,18.36,23.868,27.54,42.228,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.524-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C115.778,165.078,66.818,181.602,72.326,147.33z" />
                  <path d="M274.286,147.33c4.284-26.928,37.943-55.692,64.26-56.304c1.836,0,3.672-0.612,4.896-1.836c1.225-0.612,2.448-1.224,3.061-3.06c9.18-17.136,4.284-30.6-11.016-41.616c-17.748-12.852-45.9,0-59.977,11.628c-35.496,29.376-71.604,80.172-69.768,128.52c-6.12,32.437-4.896,67.32,4.283,96.084c6.12,18.36,23.868,27.54,42.229,28.764c18.36,1.225,56.304,6.732,72.828-4.283c16.523-11.017,17.748-32.437,19.584-50.796c1.836-20.196,7.344-58.141-9.792-74.053C317.738,165.078,268.166,181.602,274.286,147.33z" />
                </svg>
              </span>
              <h6>
                My skincare brand acquired more than 10,000 followers thanks to
                DigiPrism’s graphics and illustrations.
              </h6>
              <b>Amisha Kaushal</b>
              <p>Organic Chemical Researcher &amp; Motivator</p>
              <div className="app-bg-dot">
                <svg viewBox="0 0 266 246" fill="none">
                  <circle cx="3" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="163" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="183" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="203" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="223" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="243" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="3" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="23" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="43" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="63" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="83" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="103" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="123" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="143" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="163" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="183" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="203" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="83" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="103" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="123" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="143" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="3" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="23" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="43" r="3" fill="#F7F7F7" />
                  <circle cx="223" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="243" cy="63" r="3" fill="#F7F7F7" />
                  <circle cx="263" cy="63" r="3" fill="#F7F7F7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-faq-cover">
        <div className="app-container">
          <div className="app-faq-wrap">
            <div className="app-top-heading-cov lifestyle">
              <h3>
                Frequently Asked <span>Questions</span>
              </h3>
            </div>
            <div className="app-faq-wrapper lifestyle">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I have launched a series of cosmetic products. How will I let my customers know about it?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    Our online marketers apply more than one technique to solve your problem. We write Press Release to announce the launch of new products. Our team will also help you with Social Media Marketing to let your followers know about your beauty care products. Our professionals will pay attention to Video Marketing to promote your product and share information with potential buyers. Besides, we determine every step based on yourLaunch Goals.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I am a start-up as a lifestyle brand. Can you help me with branding?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    Our first step for branding is to create a Logo Design with a catchy tagline. Moreover, we conduct a Market Research and present you with Data Analytics report. Our marketers focus on UI and Landing Page Optimization techniques to draw customers to your site. We also write your ad copy for PPC ads, Google Ads, and other premium ads. Furthermore, with Content Marketing, Guest Posting, Link Building, and SEO, we develop your lifestyle brand visibility.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I have an online store selling personal care items. But, its bounce rate is high. How will I minimize the rate to increase sales?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    To reduce bounce rate and shopping cart abandonments, we deal with On-site SEO. With website A/B testing and Page Speed Optimization, we inspect your store’s performance. We also remove popups and help you with the most engaging Brand Storytelling solution. Our professionals check out the Optimization and update Blogging section. We redesign your shopping cart and streamline the checkout process for your store visitors.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I am a life coach with a few trainees. How will I apply digital marketing techniques to grow my business?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    To prove your skills as a life coach, you can create videos. With our Video Marketing and Viral Marketing techniques, we will reach your video content to the target audience. Besides, with our Web Design and Graphic Design solutions, we will set up your platform. We will make your site search engine optimized with our Web Content writing solutions. Moreover, our Social Media Advertising and Pay-Per-Click advertising will promote your services.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I have developed a mobile app for selling our beauty care items. How do you promote the app?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    For Mobile Marketing and Mobile App Optimization, we approach different Ad Networks. In addition, we apply the best App Store Optimization techniques to make your app visible to the Play Store and App Store users. We do a thorough Keyword Analysis to accomplish this goal. Our graphic designers also provide you the best app Icon Design solutions to attract the attention of mobile users. Besides, with App Review and Social Media Ads, we make your app highly profitable.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    I am one of the certified personal trainers. How will I build links with the target audience and other professionals?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                    Our Link Building Campaign starts with a proper LinkedIn profile setup. Moreover, with Directory Submission, we spread your business details to your potential clients. When you have a website, we help you with Deep Links, Inbound Links, and Outbound Links. We ensure that there is no deindexing risk in your website. With Guest Posting, we let you get Backlinks. Besides, our do-followed links and followed links help us to make your campaign successful.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Show me a detailed Lifestyle &amp; Personal success story.
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="divCls">
                      <b>About the company</b>
                      It is a personal care brand that established its business
                      in 2004 Due to the stiff competitive level, the company
                      had faced several obstacles to reach its targets. Still,
                      its in-house team had made effort to grow the business.
                      Nevertheless, the growth rate was low, and the company
                      could not beat its competitors. During the first few years
                      of its business, it saw no tangible result. However, when
                      we had started working with its in-house team, we found
                      the ray of hopes for the company.
                    </p>
                    <div className="divCls">
                      <b>Condition before we started the project</b>
                      <ul>
                        <li>
                          The company did not follow the present advertising
                          landscape.
                        </li>
                        <li>As a startup, it had a lack of authenticity.</li>
                        <li>
                          It could not develop long-term relationship with
                          customers.
                        </li>
                        <li>There was no considerable market share.</li>
                        <li>Revenue generation was low.</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Problem statements</b>
                      <p>
                        No proper design of the website <br />
                        Problem with URL structure <br />
                        Social media pages not properly designed <br />
                        No customer relationship development efforts.
                      </p>
                    </div>
                    <div className="divCls">
                      <b>Our objectives</b>
                      <ul>
                        <li>To increase the number of website visitors</li>
                        <li>To spread the brand name</li>
                        <li>To help the company in gaining prominence</li>
                        <li>To increase the ROI within the shortest time</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>How we achieved</b>
                      <b>SEO</b>
                      <p>
                        The official website of the personal care brand had a
                        low organic traffic rate. To increase leads and web
                        traffic, we started SEO campaign. The company targeted
                        urban millennials to sell its products. Based on this
                        target audience, we worked on the campaign.
                      </p>
                      <ul>
                        <li>
                          We created a list of LSI keywords and key phrases.
                        </li>
                        <li>Then, we modified the website architecture.</li>
                        <li>We designed SEO-friendly URL structure.</li>
                        <li>
                          Our professionals created a mobile-friendly and
                          responsive design
                        </li>
                        <li>
                          For better site speed, we worked on Shrinking
                          JavaScript and CSS elements.
                        </li>
                        <li>
                          We also removed widgets and reduced the number of http
                          requests.
                        </li>
                        <li>We created visual hierarchy for every page.</li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>SMM- Facebook and Twitter marketing</b>
                      <p>
                        The personal care brand had no presence in the social
                        platforms. That is why we created a professional-looking
                        Facebook and Twitter page for the company. Moreover, we
                        had gone through several other steps.
                      </p>
                      <ul>
                        <li>Our writers used to post blogs regularly.</li>
                        <li>
                          We engaged our SMM campaign manager to respond to your
                          followers queries.
                        </li>
                        <li>We also shared videos on customer testimonials.</li>
                        <li>
                          We educated potential buyers on the benefits obtained
                          from using personal care products.{" "}
                        </li>
                        <li>
                          Our team also posted paid ads that had a better reach.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Email marketing</b>
                      <p>
                        We started an automated email marketing for the brand.
                        It helped in strengthening customer relationship and
                        retaining more customers.
                      </p>
                      <ul>
                        <li>Our first step was to set measurable goals.</li>
                        <li>
                          Our writers created enticing email copies for
                          newsletters and promotional emails.
                        </li>
                        <li>
                          We complied with email regulations and tested the
                          content.
                        </li>
                        <li>
                          We also implemented tools to identify the open rates
                        </li>
                        <li>
                          Based on the daily performance, we edited the list of
                          subscribers.
                        </li>
                      </ul>
                    </div>
                    <div className="divCls">
                      <b>Outcomes</b>
                      <p>
                        We have achieved something that goes beyond our clients’
                        anticipation. The company has gained a high status with
                        our efforts. We have summed up our achievements
                      </p>
                      <ul>
                        <li>110% increase in revenues</li>
                        <li>70% increase in business reach</li>
                        <li>85% increase in email response</li>
                        <li>200% increase in social media followers.</li>
                      </ul>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Worktogather />
      <Socialmedia />
      {openPopup && <CaseStudyPopup openCaseStudyPopup={openCaseStudyPopup} />}
    </Layout>
  );
}
