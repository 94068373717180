import React from "react";
import Layout from "../../core/Layout";
import Socialmedia from "../socialmedia/Socialmedia";

export default function Career() {
  window.scrollTo(0, 0);
  return (
    <Layout>
      <div className='app-wrap-content-cover'>
        <span className='app-contact-content-cover'></span>
        <div className='app-pos-bg'>
          <div className='app-container'>
            <div className='app-wrap-cover-page'>
              <h2>Join Us</h2>
            </div>
          </div>
        </div>
        <div className='app-contact-wrap-cover'>
          <div className='app-container'>
            <div className='app-contact-wrapper'>
              <h3>We Are Hiring</h3>
              <h6>
                Make cool shit, get paid <br />
                Find your perfect fit at DigiPrism family
              </h6>
            </div>
            <div className='app-contact-form'>
              <div className='app-contact-filed'>
                <span>You are applying for</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Name</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Email</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Phone no.</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Total Exp.</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Current Organization</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Present Designation</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Address</span>
                <input type='text' />
              </div>
              <div className='app-contact-filed'>
                <span>Core Skills</span>
                <textarea></textarea>
              </div>
              <div className='app-contact-btn'>
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Socialmedia />
    </Layout>
  );
}
