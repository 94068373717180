import React, { useEffect, useState } from "react";
import { basepath } from "../core/config";
import Sidebar from "./Sidebar";

export default function Dashboard() {
  const [contactList, setContactList] = useState(null);
  const [quote, setQuote] = useState(null);

  const getContactList = async () => {
    const configdata = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
    };
    fetch(`${basepath}/contact`, configdata)
      .then((res) => res.json())
      .then((res) => setContactList(res.data))
      .catch((err) => console.log(err));
  };

  const getQuote = async () => {
    const configdata = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
    };
    fetch(`${basepath}/quote`, configdata)
      .then((res) => res.json())
      .then((res) => setQuote(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getContactList();
    getQuote();
  }, []);

  return (
    <div className="app-dashboard-wrapper">
      <Sidebar />
      <div className="app-dashboard-main-section">
        <div className="app-wrap-dash">
          <h4>Dashboard</h4>
          <div className="app-dash-container">
            <div className="app-dashboard-devider">
              <h4>Latest 10 Contacts</h4>
              {contactList && contactList.length ? (
                <ul>
                  <li className="app-dash-tab-head">
                    <span>Name</span>
                    <span>Email</span>
                    <span>Contact</span>
                    <span>Country</span>
                  </li>
                  {contactList.reverse().map((item) => (
                    <li key={item._id}>
                      <span>{item.name}</span>
                      <span>{item.email}</span>
                      <span>{item.contact}</span>
                      <span>{item.country}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="noData">No Data Available</div>
              )}
            </div>
            <div className="app-dashboard-devider">
              <h4>Latest 10 Quote</h4>
              {quote && quote.length ? (
                <ul>
                  <li className="app-dash-tab-head">
                    <span>Name</span>
                    <span>Email</span>
                    <span>Mobile</span>
                    <span>Country</span>
                  </li>
                  {quote.reverse().map((item) => (
                    <li key={item._id}>
                      <span>{item.name}</span>
                      <span>{item.email}</span>
                      <span>{item.mobile}</span>
                      <span>{item.country}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="noData">No Data Available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
