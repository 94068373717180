import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../core/Layout";
import Socialmedia from "../socialmedia/Socialmedia";
import Worktogather from "../worktogather/Worktogather";
import HomeAbout from "./HomeAbout";
import HomeIndustry from "./HomeIndustry";
import HomeServices from "./HomeServices";
import HomeSlider from "./HomeSlider";
import HomeTestimonials from "./HomeTestimonials";
import HomeWorks from "./HomeWorks";


export default function Home() {
  window.scrollTo(0, 0);
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <title>DigiPrism: Digital Marketing Agency; The Voice Search Expert</title>
        <meta name="description" content="DigiPrism is India’s first Industry-Specific Digital Marketing Company | Specialized Teams | SEO, SEM, Social Media, Growth Hacking | 100+ Projects | 30L+ Revenue Generated" />
      </Helmet>
      <HomeSlider />
      <HomeAbout />
      <HomeIndustry />
      <HomeServices />
      <HomeTestimonials />
      <HomeWorks />
      <Worktogather />
      <Socialmedia />
    </Layout>
  );
}
